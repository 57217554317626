import React, { useEffect } from 'react'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { Button } from '@mui/material'
import styled from 'styled-components'
import moment from 'moment'
import map from 'lodash/map'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import { DraggableDay } from './DraggableDay'
import { CalendarLoader } from './CalendarLoader'

import filter from 'lodash/filter'
import { formatDate, monthDates, MONTH, YEAR } from './utils'

const DayWrapper = styled.div`
  visibility: ${props => (props.isOutsideCurrentMonth ? 'hidden' : 'visible')};
  position: relative;
`

/**
 * Represents a button for a day in the calendar
 * */

const Day = props => {
  return (
    <Button tabIndex={-1} type="button" disabled={props.disabled} key={props.formattedDate}>
      {props.date.date()}
    </Button>
  )
}

/**
 * Styled date picker component with custom rendering logic for each day.
 * */

const StyledDesktopDatePicker = ({
  isLoading,
  selectFields,
  selectedFlightGroupDate,
  selectFlightGroup,
  calendarDots,
  activeDate,
  setActiveDate,
  droneFlightsSnapshotsDates,
  droneSnapshotsMode,
  cloudCoverage
}) => {
  const activeYearMonth = activeDate.format(MONTH)

  /**
   * Renders an individual day in the date picker
   * */

  const renderDayInPicker = date => {
    const { day, outsideCurrentMonth, disabled } = date
    const formattedDate = formatDate(day)

    const activeYearMonthDots = get(calendarDots, activeYearMonth)
    const currentDateFlightGroups = get(activeYearMonthDots, formattedDate)
    const filteredFlightGroupsByCloudCoverage = filter(currentDateFlightGroups, flightGroup => get(flightGroup, 'cloudCoverage') <= cloudCoverage)
    const flightGroupsOrderedByPrioritiesSources = orderBy(filteredFlightGroupsByCloudCoverage, ['source'], ['asc'])
    const defaultFlightGroupIndex = get(first(flightGroupsOrderedByPrioritiesSources), 'index')

    const isSelected = selectedFlightGroupDate === formattedDate
    const hasFlightGroups = !isEmpty(filteredFlightGroupsByCloudCoverage)

    const selectDate = () => {
      if (hasFlightGroups && !isSelected) {
        selectFlightGroup(defaultFlightGroupIndex)
      }
    }

    return (
      <DayWrapper role="cell" key={formattedDate} isOutsideCurrentMonth={outsideCurrentMonth}>
        {hasFlightGroups ? (
          <DraggableDay
            date={date.day}
            formattedDate={formattedDate}
            dayInCurrentMonth={!outsideCurrentMonth}
            isSelected={isSelected}
            currentDateFlightGroups={filteredFlightGroupsByCloudCoverage}
            selectDate={selectDate}
          />
        ) : (
          <Day date={day} disabled={!disabled} formattedDate={formattedDate} />
        )}
      </DayWrapper>
    )
  }

  useEffect(() => {
    droneSnapshotsMode && droneFlightsSnapshotsDates && setActiveDate(moment(droneFlightsSnapshotsDates[0]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droneSnapshotsMode])

  const onMonthChange = date => {
    const { from, to, yearMonth } = monthDates(date)
    const currentMonthDots = get(calendarDots, yearMonth)

    if (isEmpty(currentMonthDots)) {
      selectFields(from, to, true)
    }

    setActiveDate(date)
  }

  /**
   * Determines whether a specific date should be disabled based on the droneSnapshotsMode
   */
  const shouldDisable = (date, format) => {
    if (droneSnapshotsMode) {
      const formattedDate = date.format(format)
      if (format === MONTH) {
        const availableMonths = new Set(map(droneFlightsSnapshotsDates, date => moment(date).format(MONTH)))
        const isAvailable = availableMonths.has(formattedDate)
        return !isAvailable
      } else if (format === YEAR) {
        const availableYears = new Set(map(droneFlightsSnapshotsDates, date => moment(date).format(YEAR)))
        const isAvailable = availableYears.has(formattedDate)
        return !isAvailable
      } else return false
    }
  }

  return (
    <StaticDatePicker
      loading={isLoading}
      renderLoading={CalendarLoader}
      onChange={setActiveDate}
      openTo={droneSnapshotsMode && droneFlightsSnapshotsDates ? 'year' : 'day'}
      views={['year', 'month', 'day']}
      minDate={moment(new Date(2017, 0, 1))}
      maxDate={moment()}
      onMonthChange={onMonthChange}
      value={activeDate}
      shouldDisableMonth={date => droneSnapshotsMode && shouldDisable(date, MONTH)}
      shouldDisableYear={date => droneSnapshotsMode && shouldDisable(date, YEAR)}
      slots={{ day: renderDayInPicker }}
      className="calendar-field-selector"
      sx={{ background: 'transparent', '& .MuiPickersLayout-contentWrapper': { background: 'transparent' } }}
    />
  )
}

export default StyledDesktopDatePicker
