import { connect } from 'react-redux'
import Map from '../../components/olComponents/map.js'

import { getConfigData } from '../../selectors/config'
import { getFiltersSearchInputString } from '../../selectors/filters'
import { isEditorPresentActive, getEditorPresentData, isEditorMeasureToolActive } from '../../selectors/editor'
import { isFieldView, isSearchView, isTemporalView } from '../../selectors/viewmode'
import isNull from 'lodash/isNull'
import { isFieldSeasonFormEditorOpen } from '../../selectors/fieldSeasonForm.js'
import { getSelectedFlightGroupSeasons } from '../../selectors/flightGroups.js'
import { getTempComparisonIndex } from '../../selectors/calendar'
import { getFieldById } from '../../selectors/fields'
import { getZoomLevel } from '../../selectors/ui.js'
import { setZoomLevel } from '../../actions/ui.js'
import { getIsGlobalLayerLegendOpen } from '../../selectors/globalLayer.js'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { compose } from 'redux'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected.js'
import { getFieldsFilteredByView } from '../../selectors/availableFields.js'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants.js'

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => {
  const isEditorActive = isEditorPresentActive(state)
  const filteredByEverything = getFieldsFilteredByView({ fieldsWithAvailableAndSelected, filterAvailableAndSelected: false })(state)
  const filteredFeatures = state.globalLayer.neoFeatures
  const isFilterable = CHUNK_SIZE > filteredFeatures.length
  const filteredList = filteredFeatures
    .map(feature => {
      if (!feature) return null
      if (isFilterable && !filteredByEverything.find(f => f.id === feature.field.gm_field_id)) return null
      return feature
    })
    .filter(d => d)
  return {
    selectedFlightGroupSeasons: getSelectedFlightGroupSeasons(state),
    filters: getFiltersSearchInputString(state),
    searchInput: getFiltersSearchInputString(state),
    isFetchedData: getConfigData(state, 'isAppReady'),
    isEditorActive,
    activeTool: getEditorPresentData(state, 'activeTool'),
    isTemporalView: !isNull(getTempComparisonIndex(state)),
    isSearchView: isSearchView(state),
    isFieldView: isFieldView(state),
    isGlobalLayerView: getIsGlobalLayerLegendOpen(state),
    isEditorMeasureToolActive: isEditorMeasureToolActive(state),
    isAllActive: isEditorActive || isEditorMeasureToolActive(state) || isTemporalView(state),
    isFieldSeasonFormEditorOpen: isFieldSeasonFormEditorOpen(state),
    getFieldById: id => getFieldById(state, id),
    zoomLevel: getZoomLevel(state),
    filteredFeatures: filteredList
  }
}

export default compose(withTranslator, withFieldsAvailableAndSelected, connect(mapStateToProps, { setZoomLevel }))(Map)
