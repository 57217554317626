import React from 'react'

import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

import StyledListButton from '../UIComponents/StyledListButton'

function FileGeneratorButton({ t, selectedFieldsLength, isFieldView, isSatOrRadarSelected, setSelectedFieldTab }) {
  const isDisabled = !isFieldView || !isSatOrRadarSelected || selectedFieldsLength < 1

  const handleClick = () => {
    setSelectedFieldTab(6)
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_FILE_GENERATOR, {
      button: 'File Generator',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return (
    <StyledListButton
      labelDistance={6}
      onClick={handleClick}
      disabled={isDisabled}
      icon="fas fa-file-export"
      label={t('File generator')}
      testId="file-generator-button"
    />
  )
}

export default FileGeneratorButton
