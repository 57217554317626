import { connect } from 'react-redux'
import ViewerApp from '../components/ViewerApp'
import { LOADING_PAGE_VIEW, selectBaseMap } from '../actions/viewmode'
import { initializeViewer } from '../actions/initialization'

import '../utils'

import { getConfigData, getEOXXML, getConfigProjection } from '../selectors/config'
import { getSelectedBaseMap, getScreenView, getSelectedBaseMapIndex } from '../selectors/viewmode'
import { getLocale, getMessages } from '../selectors/i18n'
import { getUser } from '../selectors/users'
import { isUIRequestViewerDataInitializationSuccess, getZoomLevel } from '../selectors/ui'

const mapStateToProps = (state, props) => {
  return {
    /** projection of the map **/
    isAppReady: getConfigData(state, 'isAppReady'),
    projection: getConfigProjection(state),
    baseMap: getSelectedBaseMap(state),
    isTutorial: state.tutorial.isOpen,
    isLoading: getScreenView(state) === LOADING_PAGE_VIEW,
    locale: getLocale(state),
    messages: getMessages(state),
    user: getUser(state),
    isViewerDataLoaded: isUIRequestViewerDataInitializationSuccess(state),
    eoxXML: getEOXXML(state),
    view: getScreenView(state),
    zoomLevel: getZoomLevel(state),
    baseMapsSelectedIndex: getSelectedBaseMapIndex(state)
  }
}

const mapDispatchToProps = {
  initializeViewer,
  selectBaseMap
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewerApp)
