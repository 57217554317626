import React from 'react'
import withTranslator from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import moment from 'moment'
import first from 'lodash/first'
import map from 'lodash/map'
import truncate from 'lodash/truncate'
import capitalize from 'lodash/capitalize'
import { UIStyles } from '../../theme'
import { Avatar, Chip } from '@mui/material'
import SampleFormWrapperContainer from '../../containers/FieldsContainers/SampleFormContainers/SampleFormWrapperContainer'

const SampleWrapper = styled.div`
  color: white;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  padding: 1rem;
  border-bottom: 1px solid ${UIStyles.lightGrey};
  position: relative;
`

const Notes = styled.p`
  grid-column: 2 / 3;
  font-size: 1.3rem;
  margin: 0;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: center;
  margin-left: 2rem;
`

const DateAndUniqueId = styled.p`
  margin: 0;
  font-size: 1.3rem;
`

const UnstiledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  z-index: 1;
  width: 100%;
`

const chipStyle = {
  margin: 0,
  backgroundColor: UIStyles.darkBlue
}

const avatarStyle = {
  borderColor: UIStyles.blueLightHover,
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: 'transparent'
}

function SamplesList({ t, samples, selectSampleById, isSampleFormOpen }) {
  return (
    <div>
      <SampleFormWrapperContainer />
      {!isSampleFormOpen ? (
        <>
          {map(samples, sample => (
            <SampleWrapper key={sample.id}>
              <UnstiledButton onClick={() => selectSampleById(sample.id)} />
              <Chip
                label={capitalize(t(sample?.type?.name))}
                key={sample.id}
                style={chipStyle}
                avatar={
                  <Avatar style={avatarStyle} size={32}>
                    {first(capitalize(t(sample?.type?.name)))}
                  </Avatar>
                }
              />
              <DescriptionWrapper>
                <DateAndUniqueId>
                  {moment(sample.sampleCreationDate).format('DD-MM-YYYY')} - {sample.uniqueId || sample.externalId}
                </DateAndUniqueId>
                {sample.notes ? <Notes>{truncate(sample.notes, { length: 28, omission: '...' })}</Notes> : null}
              </DescriptionWrapper>
            </SampleWrapper>
          ))}
        </>
      ) : null}
    </div>
  )
}

export default withTranslator(SamplesList)
