// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Foarte scăzut",
  "% Mapa alertas": "% Harta avertizărilor",
  "% Media Fosforo": "% Media Fosfor",
  "% Media Nitrogeno": "% Media Azot",
  "% Media Potasio": "% Media Potasiu",
  "% resiembra": "% reînsămânțare",
  "0.10 Low": "0.10 Scăzut",
  "0.20 Very Low": "0.20 Foarte scăzut",
  "0.25 Very Low": "0.25 Foarte scăzut",
  "0.30 Low": "0.30 Scăzut",
  "0.30 Medium": "0.30 Mediu",
  "0.40  Low": "0.40  Scăzut",
  "0.40 Medium": "0.40 Mediu",
  "0.45 High": "0.45 Înalt",
  "0.50 High": "0.50 Înalt",
  "0.55 Medium": "0.55 Mediu",
  "0.65 Very High": "0.65 Foarte înalt",
  "0.70 High": "0.70 Înalt",
  "0.85 Very High": "0.85 Foarte înalt",
  "abonado de cobertera": "Fertilizare de acoperire",
  "abonado de fondo": "Fertilizare de bază",
  "abonado de fondo ": "Fertilizare de bază",
  "abonado dosificado": "Fertilizare dozată",
  "abonado tasa fija": "Fertilizare rată fixă",
  "abonado zonificado": "Fertilizare zonificată",
  "accumulated vegetative growth": "Creștere vegetativă acumulată",
  "Acre": "Acre",
  "active": "activ",
  "Add at least one recipient (+)": "Adăugați cel puțin un destinatar (+)",
  "Add Field Button": "Buton Adăugare Câmp",
  "Add Field Tutorial": "Tutorial: Adăugare câmpuri",
  "add location": "adaugă locație",
  "Add new sample": "Adaugă probă nouă",
  "add photo": "adaugă fotografie",
  "added to pending": "adăugat la în așteptare",
  "Advanced": "Avansat",
  "advanced season parameters": "parametrii avansați ai sezonului",
  "afección": "Afectare",
  "afección extensivo": "Afectare extensivă",
  "Affected Surface": "Suprafață afectată",
  "After filling the form, you can draw the field": "După completarea formularului, puteți desena câmpul",
  "agrodelivery": "Agrodelivery",
  "all": "toate",
  "ALMENDRA": "MIGDALĂ",
  "alto estrés hídrico": "Stres hidric înalt",
  "alto pot prod": "Potențial de producție înalt",
  "ambiente": "Ambient",
  "An error occured": "A apărut o eroare",
  "An error occurred from the server. Please try again": "A apărut o eroare din partea serverului. Vă rugăm să încercați din nou",
  "And that's it!": "Și asta e tot!",
  "anexo información": "Anexă informații",
  "Applied filters": "Filtre aplicate",
  "Apply": "Aplică",
  "apply date filter": "Aplică filtru de dată",
  "Apply selection": "Aplică selecția",
  "Are you sure to close the editor?": "Sunteți sigur că doriți să închideți editorul?",
  "Are You sure?": "Sunteți sigur?",
  "area": "arie",
  "Area": "Suprafață",
  "ATR": "Zahăr recuperabil total",
  "author": "Autor",
  "Automatic harvest": "Recoltă automată",
  "auxiliary files": "Fișiere auxiliare",
  "back": "înapoi",
  "bajo estrés hídrico": "Stres hidric scăzut",
  "bajo pot prod": "Potențial de producție scăzut",
  "Base temperature": "Temperatura de bază",
  "baseLayer": "Strat de bază",
  "Basic": "Bazic",
  "basic season parameters": "parametrii de bază ai sezonului",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"pată ochi\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"pată ochi\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"pată ochi\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"pată ochi\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"pată ochi\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Atât primul cât și ultimul punct al liniei de tăiere trebuie să fie în afara poligonului/cercului",
  "Buried": "Îngropat",
  "calibre": "Calibru",
  "camera error message": "Se pare că există o problemă cu permisiunile camerei",
  "camera_unavailable": "camera indisponibilă",
  "Can't modify field with active planet subscription": "Nu se poate modifica câmpul cu abonament planet activ",
  "cancel": "Anulează",
  "cane_num": "Număr tije",
  "cantidad": "Cantitate",
  "capa base": "Strat de bază",
  "Capture screenshot": "Capturează captură de ecran",
  "Change opacity": "Schimbă opacitatea",
  "Choose your layer": "Alegeți stratul dvs.",
  "Choose your tool": "Alegeți instrumentul dvs.",
  "Circle Drawing": "Desen cerc",
  "class": "Clasă",
  "Class values must be numerical": "Valorile claselor trebuie să fie numerice",
  "classes": "Clase",
  "classes number": "Numărul claselor",
  "clear": "Șterge",
  "Clear all": "Șterge tot",
  "clear form": "șterge formularul",
  "clear selected fields": "Ștergeți câmpurile selectate",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Făcând clic pe acest buton, puteți comuta vizibilitatea stratului selectat.",
  "clientDeviation": "Abaterea clientului",
  "Close": "Închide",
  "Close editor": "Închide editorul",
  "Close selection": "Închide selecția",
  "Close the temporal Comparison": "Închide comparația temporală",
  "close_selected": "Închide selectat",
  "closed": "închis",
  "cloud": "Nor",
  "Cloud Coverage": "Acoperire nori",
  "comment": "comentariu",
  "comp. temporal target": "Comp. țintă temporală",
  "comp. temporal variabilidad": "Comp. variabilitate temporală",
  "comparación de conteo": "Comparare numărare (%)",
  "comparación de estado hídrico": "Comparare stare hidrică (%)",
  "comparación de estado hídrico drone": "Comparare stare hidrică - Drone (%)",
  "comparación de estado hídrico sat": "Comparare stare hidrică - SAT (%)",
  "comparación de estimación de producción": "Comparare estimare producție (%)",
  "comparación de fósforo": "Comparare fosfor (%)",
  "comparación de mapa de alertas": "Comparare hartă avertizări (%)",
  "comparación de mapa de faltas": "Comparare hartă lipsuri (%)",
  "comparación de media de conteo": "Comparare medie numărare (%)",
  "comparación de media de estado hídrico": "Comparare medie stare hidrică (%)",
  "comparación de media de fósforo": "Comparare medie fosfor (%)",
  "comparación de media de mapa de alertas": "Comparare medie hartă avertizări (%)",
  "comparación de media de mapa de faltas": "Comparare medie hartă lipsuri (%)",
  "comparación de media de nitrógeno": "Comparare medie azot (%)",
  "comparación de media de potasio": "Comparare medie potasiu (%)",
  "comparación de media de producción total": "Comparare medie producție totală (%)",
  "comparación de media de rendimiento graso": "Comparare medie performanță grasă (%)",
  "comparación de medias de estimación de producción": "Comparare medii estimare producție (%)",
  "comparación de medias de ndvi": "Comparare medii NDVI (%)",
  "comparación de medias de proteína": "Comparare medii proteină (%)",
  "comparación de medias de variabilidad": "Comparare medii variabilitate (%)",
  "comparación de ndvi": "Comparare NDVI (%)",
  "comparación de nitrógeno": "Comparare azot (%)",
  "comparación de nitrógeno relativo": "Comparare azot relativ (%)",
  "comparación de potasio": "Comparare potasiu (%)",
  "comparación de producción total": "Comparare producție totală (%)",
  "comparación de proteína": "Comparare proteină (%)",
  "comparación de rendimiento graso": "Comparare performanță grasă (%)",
  "comparación de sacarosa": "Comparare zaharoză",
  "comparación de variabilidad": "Comparare variabilitate (%)",
  "confirm": "Confirmă",
  "Congratulations! Your field has been created.": "Felicitări! Câmpul dvs. a fost creat.",
  "Congratulations! Your field has been edited.": "Felicitări! Câmpul dvs. a fost editat.",
  "Contact": "Contact",
  "Contenido de sacarosa ( % )": "Conținut de zaharoză ( % )",
  "conteo - pdf rgb": "numărare - pdf rgb",
  "conteo - shps": "numărare - shps",
  "conteo de individuos": "Numărare de indivizi",
  "conteo lechugas": "Numărare salate",
  "continue": "continuă",
  "CORN": "PORUMB",
  "correction": "Corecție",
  "Corte nitrogeno": "Tăiere azot",
  "cost": "Cost",
  "count (units)": "Număr (unități)",
  "Counting": "Numărare",
  "create": "crează",
  "Create New Field": "Creează câmp nou",
  "CREATE_RANGES": "Bazat pe histograma imaginii, creați intervale cu un procentaj de date similare în fiecare interval.",
  "created": "Creat",
  "crecimiento cultivo": "Creștere cultură",
  "Crop": "Cultură",
  "Crop type": "Tip de cultură",
  "CROP_INSPECTION": "Inspecție cultură",
  "cultive": "Cultură",
  "Cultivo": "Cultură",
  "current season": "sezonul curent",
  "current selection could take a moment to process": "Selecția curentă ar putea dura un moment pentru a fi procesată",
  "Customer": "Client",
  "customerProductionEstimation": "Estimare producție client",
  "customers": "Client",
  "cut": "Taie",
  "Cutter": "Tăietor",
  "dashboard": "Tablou de bord",
  "date": "Dată",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "delete": "șterge",
  "Delete": "Șterge",
  "Delete downloaded report": "Șterge raportul descărcat",
  "delete downloaded sample": "șterge proba descărcată",
  "Delete field": "Șterge câmp",
  "delete layers?": "șterge straturi?",
  "delete sample": "șterge proba",
  "delete sample edition": "șterge ediția probei",
  "DeleteField": "Câmpul a fost șters",
  "Deliverables tutorial": "Tutorial: Livrabile",
  "Description": "Descriere",
  "Deselect": "Deselectează",
  "deselect all": "Deselectează tot",
  "deviation": "Abatere",
  "Did you forget your password ?": "Ați uitat parola?",
  "disponible en visor": "Disponibil în vizualizator",
  "do you want to delete": "doriți să ștergeți",
  "documentation": "Documentație",
  "don't show again": "nu mai arăta din nou",
  "Done": "Gata",
  "dosificación genérica": "Dozare generică",
  "Dosis Media Cliente": "Doză medie client",
  "Dosis media Propuesta": "Doză medie propusă",
  "dosis riego": "Doză irigație",
  "Download": "Descarcă",
  "download failed": "Descărcările următoare nu au putut fi finalizate",
  "Download list": "Listă descărcare",
  "Download selected fields samples": "Descarcă probele câmpurilor selectate",
  "download selected geometries": "Descarcă geometriile selectate",
  "download values": "Descarcă valori",
  "downloaded": "descărcat",
  "downloading layers": "descărcare straturi",
  "downloads": "Descărcări",
  "Drag and drop your layers to arrange them in the list.": "Trageți și plasați straturile pentru a le aranja în listă",
  "Drag Fields": "Trageți câmpuri",
  "Drag your layers": "Trageți straturile",
  "Draw Field": "Desenează câmp",
  "dron": "Dronă",
  "Dron sectores": "Sectoare dronă",
  "drop here": "lasă aici",
  "Dry": "Uscat",
  "dry matter": "Materie uscată",
  "dryMatterField": "Materie uscată câmp",
  "dryMatterLaboratory": "Laborator materie uscată",
  "dsm": "DSM",
  "earliest harvest date": "Data cea mai timpurie de recoltare",
  "edit": "editează",
  "Edit Field": "Editează câmp",
  "Edit geometry": "Editează geometrie",
  "edit range": "Editează interval",
  "Edit values": "Editează valori",
  "ej 100": "Ex 100",
  "End Date": "Data de sfârșit",
  "Equal Interval": "Interval Egal",
  "Equidistant ranges": "Intervale echidistante",
  "error fetching classes": "Nu există informații despre clase pentru selecția curentă. Încercați o altă combinație de câmp/dată.",
  "estado hídrico": "Stare hidrică",
  "estado hídrico (ndmi)": "Stare hidrică (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Estimare",
  "estimación producción": "Estimare producție",
  "Estimacion produccion ( Tn / Ha )": "Estimare producție ( Tn / Ha )",
  "estimación producción cualitativa": "Estimare producție calitativă",
  "estimación proteína": "Estimare proteină",
  "Estimacion proteina ( % )": "Estimare proteină ( % )",
  "estimación sacarosa": "Estimare zaharoză",
  "estimación sacarosa ": "Estimare zaharoză",
  "estimación sacarosa cualitativa": "Estimare zaharoză calitativă",
  "Estimacion Total ( Kg )": "Estimare totală ( Kg )",
  "estimation gauging": "Estimare măsurare",
  "estrategia de dosificacion": "Strategie de dozare",
  "estrés hídrico (lws)": "Stres hidric (LWS)",
  "evolución de NDVI": "Evoluție NDVI",
  "evolution": "evoluție",
  "Ex : comment": "Ex : comentariu",
  "Ex : field R02": "Ex : câmp R02",
  "Ex : Tresbolillos, row, tree": "Ex : Tresbolillos, rând, copac",
  "expected_final_weight": "Greutate finală așteptată",
  "external_id": "ID extern",
  "extra parameters": "parametrii extra",
  "falhas carreadores": "Goluri transportoare",
  "falhas internas": "Goluri interne",
  "falhas terraços": "Goluri terase",
  "fat performance": "Performanță grasă",
  "faults map": "Hartă defecțiuni",
  "fdn": "FDN",
  "Fecha de vuelo": "Data zborului",
  "Fecha Plantacion": "Data plantării",
  "Feddan": "Feddan",
  "fertilization strategy": "Strategie de fertilizare",
  "Fertilizer recommendation": "Recomandare îngrășământ",
  "field": "câmp",
  "Field Name": "Numele câmpului",
  "Field without geometry": "Câmp fără geometrie",
  "FIELD_FILTER": "Filtre câmp",
  "field_name": "Numele câmpului",
  "FIELD_TEMPORAL_EVOLUTION": "Evoluție temporală câmp",
  "fields": "Câmpuri",
  "Fields without geometries": "Câmpuri fără geometrii",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Fișierul nu poate fi generat din cauza cantității mari de nori. Vă rugăm să selectați o altă dată",
  "File generator": "Generator de fișiere",
  "File name": "Numele fișierului",
  "file type": "Tipul fișierului",
  "Fill plantation date of current field, showing one year of historic data": "Completați data plantării câmpului curent, afișând un an de date istorice",
  "Fill the field form": "Completați formularul câmpului",
  "Fill the form to apply for all our services": "Completați formularul pentru a aplica pentru toate serviciile noastre",
  "filtered field": "câmp filtrat",
  "filtered fields": "câmpuri filtrate",
  "Filters": "Filtre",
  "final_weight": "Greutate finală",
  "fitopatógenos": "Fitopatogeni",
  "Flight Slider": "Slider de zbor",
  "FLIGHT_FILTER": "Comparație temporală",
  "flights type": "Tipuri de zboruri",
  "fmsavi": "FMSAVI",
  "for everyone": "pentru toți",
  "for me": "pentru mine",
  "forecasts": "Previziuni",
  "formulario de abonado": "Formular de abonare",
  "formulario de dosis": "Formular de doză",
  "fósforo foliar": "Fosfor foliar",
  "fr": "Franciu",
  "Fraction": "Fracție",
  "free": "gratuit",
  "from": "De la",
  "fruits_100_weight": "Greutatea a 100 de fructe",
  "fruits_actual1_weight": "Greutatea fructelor actuale",
  "Fuente de informacion": "Sursă de informații",
  "gallery": "galerie",
  "gauging date": "Data măsurării",
  "generation error": "Eroare de generare",
  "geometry": "geometrie",
  "get a free trial": "obțineți un trial gratuit",
  "Get back to editor": "Întoarceți-vă la editor",
  "global files": "Descărcați fișierele PDF și CSV de preprocesare, antrenament și prognoză",
  "global layer": "Strat global",
  "gm_cut_number": "Numărul de tăieri",
  "gm_data_generation": "Data satelitului (săptămâni)",
  "gm_day_cycle": "Zile de cultivare",
  "gm_harvest_estimated_date": "Data estimată a recoltei (săptămâni)",
  "gm_hemav_atr_estimation": "Prognoza TRS",
  "gm_hemav_pol_estimation": "Prognoza polarizării",
  "gm_hemav_prodph_estimation": "Prognoza randamentului",
  "gm_hemav_realprodph_lastseason": "Randamentul real din sezonul anterior",
  "gm_hemav_sac_estimation": "Prognoza zaharozei",
  "gm_luvi_date_generation": "Data satelitului - LUVI (săptămâni)",
  "gm_luvi_day_cycle": "Zile de cultivare (NDVI-LUVI)",
  "gm_luvi_mean": "Vigoare vegetativă (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilitate (NDVI-LUVI)",
  "gm_lws_mean": "Stres hidric (LWS)",
  "gm_ndmi_date_generation": "Data satelitului - NDMI (săptămâni)",
  "gm_ndmi_day_cycle": "Zile de cultivare (NDMI)",
  "gm_ndre_date_generation": "Data satelitului - NDRE (săptămâni)",
  "gm_ndre_day_cycle": "Zile de cultivare (NDRE)",
  "gm_season_label": "Eticheta sezonului",
  "gm_sub_type_name": "Varietate",
  "gm_type_name": "Cultură",
  "go to field": "Mergi la câmp",
  "grid": "grilă",
  "harvest date": "Data recoltei",
  "harvest planning": "Planificarea recoltei",
  "HARVEST_PLANNING_TOOL": "Instrument de planificare a recoltei",
  "HARVEST_PREDICTION": "Prognoza recoltei",
  "Hectare": "Hectar",
  "Hemav Default": "Implicit Hemav",
  "HEMAV Default": "HEMAV Implicit",
  "Here, you can play with the preview of your different layers.": "Aici, puteți juca cu previzualizarea diferitelor straturi ale dvs.",
  "hide": "ascunde",
  "Hide All Layers": "Ascunde toate straturile",
  "hide filter list": "Ascunde lista de filtre",
  "Hide Global Layer": "Ascunde stratul global",
  "Hide Measure Tools": "Ascunde instrumentele de măsurare",
  "high": "Înalt",
  "high level": "Nivel înalt",
  "high need": "Nevoie mare",
  "higher clay content": "Conținut mai mare de argilă",
  "humedad de suelo - beta (%)": "Umiditatea solului - Beta (%)",
  "humedad vegetativa (ndmi)": "Umiditate vegetativă (NDMI)",
  "Hybrid View": "Vedere hibridă",
  "hydric status": "Stare hidrică - NDMI",
  "hydric status drone": "Stare hidrică - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "Dacă nu s-a făcut eșantionarea foliară, data pe care trebuie să o introduceți este cea curentă",
  "If you have two or more flights, you can compare them temporally using this tool.": "Dacă aveți două sau mai multe zboruri, le puteți compara temporal folosind acest instrument.",
  "If you have undone an action, you can press this button to redo it.": "Dacă ați anulat o acțiune, puteți apăsa acest buton pentru a o reface.",
  "image": "imagine",
  "Impacto estimado": "Impact estimat",
  "In this tab, you can download the generated PDFs.": "În această filă, puteți descărca PDF-urile generate.",
  "individuals_num": "Număr de indivizi",
  "infection": "Infecție",
  "Information": "Informații",
  "informe": "Raport",
  "integración con maquinaria": "Integrare cu mașinării",
  "Invalid credentials": "Credențiale invalide",
  "invalid date": "Dată invalidă",
  "Invalid email": "Email invalid",
  "invest": "Investește",
  "Irrigation recommendation": "Recomandare de irigație",
  "Irrigation type": "Tip de irigație",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Se ajustează în mod obișnuit pentru a stabili cantitatea de îngrășământ sau semințe care se va aplica pe unitate de suprafață.",
  "item_num": "Numărul articolului",
  "Keep selection": "Păstrează selecția",
  "label": "01.Etichetă",
  "Label": "Etichetă",
  "languaje": "Limbaj",
  "last sample": "Ultima probă",
  "lat": "Latitudine",
  "layers": "Straturi",
  "layers estrés hídrico (lws)": "Straturi stres hidric (LWS)",
  "layers status": "Status straturi",
  "Legal limitation": "Limitare legală",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"pată inelară\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"pată inelară\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"pată inelară\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"pată inelară\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"pată inelară\" - g5",
  "letter only": "Doar litere",
  "linear meters": "metri liniari",
  "líneas de cultivo": "Linii de cultură",
  "list": "listă",
  "loading": "Încărcare",
  "location": "Locație",
  "Log in": "Autentificare",
  "logout": "Deconectare",
  "lon": "Longitudine",
  "looks like its taking too long": "pare că durează prea mult",
  "low": "Scăzut",
  "low level": "Nivel scăzut",
  "low need": "Nevoie scăzută",
  "lower clay content": "Conținut mai scăzut de argilă",
  "LUVI variability": "Variabilitate LUVI",
  "Machines Type": "Tipuri de mașini",
  "main_macronutrient": "Macronutrient principal",
  "malas hierbas": "Buruieni",
  "malas hierbas - hoja estrecha": "Buruieni - frunză îngustă",
  "malas hierbas - hoja larga": "Buruieni - frunză largă",
  "malas hierbas - zonificado": "Buruieni - zonificat",
  "malas hierbas shps": "Buruieni SHPs",
  "malas hierbas shps - hoja estrecha": "Buruieni SHPs - frunză îngustă",
  "malas hierbas shps - hoja larga": "Buruieni SHPs - frunză largă",
  "malas hierbas shps - zonificado": "Buruieni SHPs - zonificat",
  "Manage report": "Gestionează raportul",
  "max 2 years": "Maxim 2 ani",
  "max comparable fields": "Maxim câmpuri comparabile",
  "maximo de variacion": "Variation maximă",
  "máximo vigor dos meses (ndvi)": "Vigoare maximă două luni (NDVI)",
  "máximo vigor un año (ndvi)": "Vigoare maximă un an (NDVI)",
  "Maximun cloud coverage": "Acoperire maximă cu nori",
  "mean": "Medie",
  "Measures tool tutorial": "Tutorial: Instrumente de măsurare",
  "Measures tools": "Instrumente de măsurare",
  "media de faltas": "Media lipsurilor",
  "medias": "Medii",
  "medio pot prod": "Potențial de producție mediu",
  "medium": "Mediu",
  "medium clay content": "Conținut mediu de argilă",
  "medium level": "Nivel mediu",
  "medium_weight_bigSize": "Greutate medie fructe mari",
  "medium_weight_mediumSize": "Greutate medie fructe medii",
  "medium_weight_smallSize": "Greutate medie fructe mici",
  "Mensual limit": "Limită lunară",
  "Message": "Mesaj",
  "METEOROLOGICAL_STATISTICS": "Statistici meteorologice",
  "mode": "Mod",
  "model type": "Tipul modelului",
  "Move the slider": "Mută cursorul",
  "msavi": "MSAVI",
  "N_BUNCHES": "Număr de ciorchini",
  "Name": "Nume",
  "nameless field": "Câmp fără nume",
  "Natural Breaks (Jenks)": "Pauze Naturale (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI real",
  "ndvi-curvas de nivel": "NDVI-Curbe de nivel",
  "Network request failed": "Eroare de rețea",
  "new sample": "probă nouă",
  "New sample created.": "Probă nouă creată.",
  "NITRO": "Azot",
  "nitrogen chlorophyll (ndre)": "Azot-clorofilă (NDRE)",
  "nitrogen_assimilable": "Azot asimilabil",
  "nitrógeno foliar": "Azot foliar",
  "Nivel de estres": "Nivel de stres",
  "no data": "Fără date",
  "no data available": "Nu sunt disponibile date încă",
  "no data with current selection": "Fără date cu selecția curentă",
  "No fields selected": "Niciun câmp selectat",
  "no fields to clear": "Niciun câmp de șters",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Nu există imagini pentru această zi.",
  "Not a user yet ?": "Nu ești încă utilizator?",
  "not enough data to display this chart": "Nu sunt suficiente date pentru a afișa acest grafic",
  "Not supported by browser": "Nu este suportat de browser",
  "notes": "note",
  "number only": "Doar numere",
  "Numero vuelo": "Numărul zborului",
  "observations": "observații",
  "offline access": "acces offline",
  "offline mode": "Mod offline",
  "oil_yield": "Randament ulei",
  "ok": "Ok",
  "Once you are done, click this button to save the changes.": "Odată ce ați terminat, faceți clic pe acest buton pentru a salva modificările.",
  "open": "deschide",
  "Open": "Deschide",
  "Open selection": "Deschide selecția",
  "open temporal comparison": "Deschide comparația temporală",
  "open_selected": "Deschide selectat",
  "optimize": "Optimizează",
  "or": "sau",
  "Order by a - z": "Ordonează de la a la z",
  "Order by distance to the field": "Ordonează după distanța până la câmp",
  "Order by z - a": "Ordonează de la z la a",
  "ourDeviation": "Abaterea Hemav",
  "ourProductionEstimation": "Estimarea producției Hemav",
  "Parcela": "Parcelă",
  "Password": "Parolă",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD arbore",
  "pcd interpolado": "PCD interpolat",
  "pcd row": "PCD rând",
  "pdf": "PDF",
  "pending": "în așteptare",
  "phospohorus": "Fosfor",
  "phosporus": "Fosfor (ppm)",
  "photo": "fotografie",
  "photoid": "ID fotografie",
  "photoId": "Fotografie",
  "picture_path": "Cale imagine",
  "plague": "Ciumă",
  "planned dosage": "Dozaj planificat",
  "Plant emitters": "Emițătoare plante",
  "plantation date": "Data plantării",
  "Plantation date": "Data plantării",
  "plantation_end_date_range": "Interval data plantării",
  "plantation_start_date_range": "Interval data plantării",
  "Plantations": "Plantații",
  "plants": "Plante",
  "Plants distance": "Distanța plantelor",
  "plants_density_range": "Interval densitate plante",
  "Please wait or order one to have data to visualized.": "Comandați un zbor pentru a obține livrabile pentru acel câmp",
  "Please, choose a field variety": "Vă rugăm să alegeți o varietate de câmp",
  "Please, choose the field owner": "Vă rugăm să alegeți proprietarul câmpului",
  "plural selected": "selectate",
  "POL": "Polarizare",
  "Polygon drawing": "Desen poligon",
  "positive number only": "Doar număr pozitiv",
  "positive number only and minor 100": "Doar număr pozitiv și mai mic de 100",
  "pot. horticolar 90": "pot. horticol 90",
  "pot. otros cultivos 150": "pot. alte culturi 150",
  "pot. otros cultivos 90": "pot. alte culturi 90",
  "potasio foliar": "Potasiu foliar",
  "potencial productivo histórico (ndvi)": "Potențial productiv istoric (NDVI)",
  "precio": "Preț",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Intervale predefinite de către HEMAV care sunt în general potrivite pentru majoritatea câmpurilor.",
  "prediction": "Predicție",
  "preprocessing": "Preprocesare",
  "Press this button to add a new field.": "Apăsați acest buton pentru a adăuga un câmp nou.",
  "Pressing this button lets you go back to the field view. ": "Apăsând acest buton vă permite să vă întoarceți la vizualizarea câmpului.",
  "Preview": "Previzualizare",
  "price Kg": "Preț pe kg",
  "primer vuelo": "Primul zbor",
  "PROD": "Randament pe unitatea de suprafață",
  "producción esperada": "Producție estimată",
  "product Kg": "Produs Kg",
  "product Kg is mandatory": "Produsul Kg este obligatoriu",
  "Production estimate": "Estimare producție",
  "production estimate (units)": "Estimare producție (Kg/Ha)",
  "profile": "Profil",
  "protein": "Proteină",
  "puccinia_sp_g1": "puccinia sp \"roya\" - g1",
  "puccinia_sp_g2": "puccinia sp \"roya\" - g2",
  "puccinia_sp_g3": "puccinia sp \"roya\" - g3",
  "puccinia_sp_g4": "puccinia sp \"roya\" - g4",
  "puccinia_sp_g5": "puccinia sp \"roya\" - g5",
  "Quick start with Azure": "Start rapid cu Azure",
  "Random fertilization": "Fertilizare aleatorie",
  "Random seedling": "Însămânțare aleatorie",
  "Range": "Interval",
  "Ranges adjusted to the index": "Intervale ajustate la indice",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Intervale de aceeași mărime vor fi generate în funcție de valorile minime și maxime ale imaginii. În cazurile în care imaginea este foarte omogenă, este posibil să nu se observe nicio diferențiere.",
  "realProduction": "Producție reală",
  "Recipients": "Destinatari",
  "recomendación abonado dosificado": "Prescripție fertilizare dozată",
  "recomendación abonado zonificado": "Recomandare fertilizare zonificată",
  "Recomendacion de riego": "Recomandare irigație",
  "recomendación riego": "Recomandare irigație",
  "Redo Action": "Refă acțiunea",
  "Related date": "Data asociată",
  "relative nitrogen": "Azot relativ",
  "Rendimiento graso ( % )": "Performanță grasă ( % )",
  "report": "Raport",
  "Report sent correctly": "Raport trimis corect",
  "report type": "Tip",
  "Report type": "Tip raport",
  "report_type_action_description": "Propunere de tratamente sau acțiuni de aplicat",
  "report_type_annotation_description": "Diagnostic: Analiză în câmp",
  "report_type_inspection_description": "Analiză din STRATURI (se închide odată ce este analizată)",
  "report_type_result_description": "Analiză a rezultatelor soluției sau tratamentului aplicat.",
  "reporte agronómico": "Raport agronomic",
  "Reported by": "Raportat de",
  "Reported fields": "Câmpuri raportate",
  "reports manager": "Rapoarte",
  "required": "necesar",
  "Required": "Necesar",
  "Requires 3 characters minimun": "Necesită minim 3 caractere",
  "Reset": "Resetare",
  "resiembra": "Reînsămânțare",
  "resolution": "Rezoluție",
  "resolution (only SHP)": "Rezoluție (doar pentru SHP)",
  "retención de agua": "Reținere de apă",
  "retry": "reîncearcă",
  "rgb": "RGB",
  "richness": "Bogăție",
  "riego zonificado": "Irigare zonificată",
  "riesgo malherbología": "Risc de buruieni",
  "Ruler": "Riglă",
  "SAC": "Zaharoză pe unitatea de suprafață",
  "saccharose estimate": "Estimare zaharoză",
  "sample": "Probă",
  "sample type": "tip",
  "Sample was deleted.": "Proba a fost ștearsă.",
  "Sample was successfully modified.": "Proba a fost modificată cu succes.",
  "sample_creation_date": "Data creării",
  "samples": "probe",
  "satélite": "Satelit",
  "Satellite button tutorial": "Tutorial: Vizualizare satelit",
  "Satellite View": "Vedere satelit",
  "save": "Salvează",
  "Save field": "Salvează câmp",
  "save_": "salvează",
  "search": "Caută",
  "Search": "Căutare",
  "season": "sezon",
  "Season label": "Etichetă sezon",
  "season name": "nume sezon",
  "SEASON_EVOLUTION": "Evoluție sezon",
  "Sector": "Sector",
  "sectores target": "Sectoare țintă",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Vezi? Acum, făcând clic din nou pe buton, te poți întoarce la vizualizarea satelitului.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Al doilea zbor",
  "Select": "Selectează",
  "select a crop and sample": "Selectați o cultură și o probă",
  "select all": "Selectează tot",
  "Select all": "Selectează tot",
  "select at least one field": "Selectați cel puțin un câmp",
  "select fields by": "Selectează câmpuri după",
  "select fields by variability range": "selectează câmpuri după intervalul de variabilitate",
  "select fields to open temporal comparison": "Selectați câmpuri pentru a deschide comparația temporală",
  "select one": "Selectează unul",
  "select one customer please": "Selectați un client, vă rugăm",
  "select one subtype please": "Selectați un subtip, vă rugăm",
  "select one type please": "Selectați un tip, vă rugăm",
  "select sample base layer and date": "selectați stratul de bază și data probei",
  "select sample location": "selectați locația probei",
  "select season": "selectați sezonul",
  "selected": "selectat",
  "Selected fields names": "Câmpuri selectate",
  "Selected fields samples": "Probe câmpuri selectate",
  "Selection includes too much information, try selecting a shorter date range": "Selecția include prea multe informații, încercați să selectați un interval de date mai scurt",
  "Send": "Trimite",
  "Send report": "Trimite raport",
  "settings": "Setări",
  "Share fields report": "Distribuie raportul câmpurilor",
  "show all": "arată tot",
  "Show All Layers": "Arată toate straturile",
  "Show current season only": "Arată doar sezonul curent",
  "show fields with no data": "Arată câmpurile fără date",
  "show filter list": "Arată lista de filtre",
  "Show Global Layer": "Arată stratul global",
  "Show Measure Tools": "Arată instrumentele de măsurare",
  "shp-tooltip": "Acești numere pot fi modificați în funcție de nevoile de utilizare și sunt asociate fiecărei clase de geometrie din tabelul de atribute SHP exportat. De obicei, sunt ajustați pentru a stabili cantitatea de îngrășământ sau de semințe care va fi aplicată per unitate de suprafață.",
  "singular selected": "selectat",
  "Slice tool only works on Polygons and Circle": "Instrumentul de tăiere funcționează doar pe poligoane și cerc",
  "Slider Tool": "Instrument cursor",
  "soil": "Sol",
  "soil_type": "Tipul solului",
  "sólo para la generación de SHP": "Doar pentru generarea SHP",
  "Sort": "Sortează",
  "sort by date": "sortează după dată",
  "Start date": "Data de început",
  "static layers": "straturi statice",
  "Statistics": "Statistici",
  "status": "Stare",
  "stem_num": "Număr tulpină",
  "Streets distance": "Distanța străzilor",
  "Subtype": "Subtip",
  "Suc/Ha": "Zah/Ha",
  "sum_of_bases": "Suma bazelor",
  "Superficie ( Ha )": "Suprafață ( Ha )",
  "Superficie Sector ( Ha )": "Suprafață Sector ( Ha )",
  "surfaceUnit": "Unitate de suprafață",
  "switch between all samples and current season samples": "comutați între toate probele și probele sezonului curent",
  "Switch to hybrid map": "Comută la harta hibridă",
  "Switch to Mapbox": "Comută la harta satelit2",
  "Switch to satellite map": "Comută la harta satelit",
  "Switch to Terrain map": "Comută la harta teren",
  "target date": "Data țintă",
  "target multiparcela": "Țintă multifield",
  "tell us": "Spune-ne",
  "Temporal Comparison tutorial": "Tutorial: Comparație temporală",
  "textura del suelo": "Textura solului",
  "The date should be in DD/MM/YYYY format": "Data de referință trebuie să fie validă în formatul DD/MM/YYYY",
  "The distance must be in meters": "Distanța trebuie să fie în metri",
  "the extra parameters will be removed": "parametrii extra vor fi eliminați",
  "the following layers are no longer available in offline mode, download again if necessary": "următoarele straturi nu mai sunt disponibile în modul offline, descărcați din nou dacă este necesar",
  "The production must be numeric/decimal": "Producția trebuie să fie numerică/zecimală",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "Coloana din dreapta arată cantitatea totală de aplicat conform valorii introduse pe clasă și totalul pe parcelă în partea de jos.",
  "The sample location must be inside one of the fields.": "Locația probei trebuie să fie în interiorul unuia dintre câmpuri.",
  "There are no samples to download": "Nu există probe de descărcat",
  "There is no operated flight on this field.": "Nu există zbor operat pe acest câmp",
  "Thermal Accumulation": "Acumulare termică",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Aceste numere pot fi modificate în funcție de nevoile aplicației și sunt asociate fiecărei geometrie în tabelul de atribute al SHP-ului exportat.",
  "This action will delete the field and all its related data": "Această acțiune va șterge câmpul și toate datele sale asociate",
  "this action will modify your field selection": "această acțiune va modifica selecția dvs. de câmp",
  "this action will open field view and lose current field selection": "această acțiune va deschide vizualizarea câmpului și va pierde selecția actuală a câmpului",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Acest buton vă permite să desenați linii sau poligoane pentru a măsura o distanță sau o suprafață.",
  "This button allows you to switch to the hybrid view.": "Acest buton vă permite să comutați la vizualizarea hibridă.",
  "This can take a long time": "Acest lucru poate dura mult timp",
  "This field is not downloaded.": "Acest câmp nu este descărcat",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Acest instrument vă permite să tăiați poligoane. Utilizați clicuri simple pentru a desena linia și un clic dublu pentru a termina desenul.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Acest instrument vă permite să desenați poligoane. Încercați să desenați unul simplu. Asigurați-vă că îl închideți!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Acest instrument vă permite să măsurați distanța dintre un set de puncte. Utilizați clicuri simple pentru a seta punctele și un clic dublu pentru a termina desenul.",
  "Those fields are mandatory": "Aceste câmpuri sunt obligatorii",
  "TIME_COMPARISON_DROP_ZONE": "Lasa aici...",
  "TINTA BARROCA": "TINTA BARROCA",
  "TINTA FRANCISCA": "TINTA FRANCISCA",
  "TINTO": "ROȘU",
  "TINTO CÃO": "CÂINE ROȘU",
  "tipo de dosificacion": "Tip de dozare",
  "to": "Până la",
  "today": "azi",
  "todos": "Toate",
  "tons of cane - annual vision": "Tone de trestie - Viziune anuală",
  "Total": "Total",
  "total production": "Producție totală (Kg)",
  "total_fruits_bigSize": "Total fructe mari",
  "total_fruits_mediumSize": "Total fructe medii",
  "total_fruits_smallSize": "Total fructe mici",
  "total_weight": "Greutate totală",
  "total_weight_bigSize": "Greutate totală fructe mari",
  "total_weight_canes": "Greutate totală trestii",
  "total_weight_mediumSize": "Greutate totală fructe medii",
  "total_weight_smallSize": "Greutate totală fructe mici",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Antrenament",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Încercați aplicația noastră mobilă pentru o experiență mai bună",
  "type": "Cultură",
  "Type": "Tip cultură",
  "type here your notes": "tastați aici notele dvs.",
  "Type your text": "Tastați textul dvs.",
  "type_sampler": "Tip eșantion",
  "Undo Action": "Anulează acțiunea",
  "unidades": "Unități",
  "unitSystem": "Sistem de unități",
  "Unknown": "Necunoscut",
  "unnamed field": "Câmp fără nume",
  "unsaved changes will be lost": "modificările nesalvate vor fi pierdute",
  "update sample": "actualizează proba",
  "updated": "Actualizat",
  "Use this buttons to select which layer to compare.": "Utilizați acest buton pentru a selecta straturile pe care doriți să le comparați.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Utilizați acest instrument pentru a compara două zboruri temporal. Pentru a începe, trageți unul dintre zboruri în cel cu care doriți să-l comparați.",
  "Use this tool to freely move the created polygons.": "Utilizați acest instrument pentru a muta liber poligoanele create.",
  "used": "utilizat",
  "Username": "Nume de utilizator",
  "users": "Utilizatori",
  "Value": "Valoare",
  "variabilidad multiparcela": "Variabilitate multifield",
  "variabilidad parcela (vra)": "Zonă cu rată variabilă (VRA)",
  "variability": "Variabilitate",
  "Variedad": "Subcultură",
  "vegetative growth": "Creștere vegetativă",
  "vegetative growth per day": "Creștere vegetativă acumulată pe zi",
  "vegetative growth variability": "Variabilitate creștere vegetativă",
  "Ver detalles": "Vezi detalii",
  "very high": "Foarte înalt",
  "very high level": "Nivel foarte înalt",
  "very low": "Foarte scăzut",
  "very low level": "Nivel foarte scăzut",
  "view": "vedere",
  "vigor": "VIGOARE",
  "vigor a nivel árbol": "vigoare / plantă",
  "vigor acumulado dos meses (ndvi)": "Vigoare acumulată două luni (NDVI)",
  "vigor acumulado un año (ndvi)": "Vigoare acumulată un an (NDVI)",
  "vigor vegetativo": "Vigoare vegetativă",
  "vigor vegetativo - pcd": "PCD - Vigoare vegetativă",
  "vigor vegetativo (ndvi)": "Vigoare vegetativă (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vigoare vegetativă (NDVI) - Etape timpurii",
  "vigor zonificado": "Vigoare zonificată",
  "VINHÃO": "VINHÃO",
  "Visibility": "Vizibilitate",
  "Vulnerable area": "Zonă vulnerabilă",
  "wait": "așteaptă",
  "warning": "avertisment",
  "warning map": "Hartă avertizare",
  "Water level": "Nivelul apei",
  "We are processing the data for your flight. Results will be available soon.": "Procesăm datele zborului dvs. Rezultatele vor fi disponibile în curând.",
  "weeds area": "Zonă buruieni",
  "week": "Săptămână",
  "weight_1": "Greutate 1",
  "weight_2": "Greutate 2",
  "weight_3": "Greutate 3",
  "weight_4": "Greutate 4",
  "weight_5": "Greutate 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Cu acest instrument puteți desena cercuri. Faceți clic pentru a plasa centrul și faceți clic din nou pentru a alege raza.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Cu acest instrument, puteți desena un poligon cu 3 sau mai multe puncte pentru a măsura suprafața sa.",
  "without warning": "Fără avertizare",
  "Wrong credentials": "Credențiale greșite",
  "year": "An",
  "years": "Ani",
  "You can change here the information of the new field.": "Puteți schimba aici informațiile noului câmp.",
  "You can move this bar to compare the layers temorally.": "Puteți muta această bară pentru a compara straturile temporal.",
  "You can only compare dates from the same sources": "Puteți compara doar date din aceleași surse",
  "You can use this button at any moment to undo your last actions.": "Puteți folosi acest buton în orice moment pentru a anula ultimele acțiuni.",
  "You can use this slider to change the opacity of the selected layer.": "Puteți folosi acest cursor pentru a schimba opacitatea stratului selectat.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Nu puteți crea câmp pentru că nu aveți clienți. Vă rugăm să ne cereți să adăugăm unii.",
  "You must enter a decimal": "Trebuie să introduceți un zecimal",
  "You must enter an integer": "Trebuie să introduceți un întreg",
  "You must select an irrigation type": "Trebuie să selectați un tip de irigație",
  "You must type a field name": "Trebuie să tastați un nume de câmp",
  "You must type a text": "Trebuie să tastați un text",
  "Your field has been successfully deleted.": "Câmpul dvs. a fost șters cu succes",
  "Your LAYERS version has expired. Click to update.": "Versiunea dvs. LAYERS a expirat. Faceți clic pentru a actualiza."
}
