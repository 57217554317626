// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Veľmi nízke",
  "% Mapa alertas": "% Mapa varovaní",
  "% Media Fosforo": "% Priemerný fosfor",
  "% Media Nitrogeno": "% Priemerný dusík",
  "% Media Potasio": "% Priemerný draslík",
  "% resiembra": "% Presev",
  "0.10 Low": "0.10 Nízke",
  "0.20 Very Low": "0.20 Veľmi nízke",
  "0.25 Very Low": "0.25 Veľmi nízke",
  "0.30 Low": "0.30 Nízke",
  "0.30 Medium": "0.30 Stredné",
  "0.40  Low": "0.40  Nízke",
  "0.40 Medium": "0.40 Stredné",
  "0.45 High": "0.45 Vysoké",
  "0.50 High": "0.50 Vysoké",
  "0.55 Medium": "0.55 Stredné",
  "0.65 Very High": "0.65 Veľmi vysoké",
  "0.70 High": "0.70 Vysoké",
  "0.85 Very High": "0.85 Veľmi vysoké",
  "abonado de cobertera": "Hnojenie pokryvu",
  "abonado de fondo": "Základné hnojenie",
  "abonado de fondo ": "Základné hnojenie",
  "abonado dosificado": "Dávkované hnojenie",
  "abonado tasa fija": "Hnojenie s pevnou sadzbou",
  "abonado zonificado": "Zonované hnojenie",
  "accumulated vegetative growth": "Nahromadený vegetatívny rast",
  "Acre": "Aker",
  "active": "aktívne",
  "Add at least one recipient (+)": "Pridajte aspoň jedného príjemcu (+)",
  "Add Field Button": "Tlačidlo Pridať pole",
  "Add Field Tutorial": "Návod: Pridať pole",
  "add location": "pridať umiestnenie",
  "Add new sample": "Pridať novú vzorku",
  "add photo": "pridať fotografiu",
  "added to pending": "pridané do čakania",
  "Advanced": "Pokročilé",
  "advanced season parameters": "pokročilé parametre sezóny",
  "afección": "Postihnutie",
  "afección extensivo": "Rozsiahle postihnutie",
  "Affected Surface": "Postihnutá plocha",
  "After filling the form, you can draw the field": "Po vyplnení formulára môžete nakresliť pole",
  "agrodelivery": "AgroDelivery",
  "all": "všetko",
  "ALMENDRA": "MANDĽA",
  "alto estrés hídrico": "Vysoký vodný stres",
  "alto pot prod": "Vysoký potenciál produkcie",
  "ambiente": "Prostredie",
  "An error occured": "Vyskytla sa chyba",
  "An error occurred from the server. Please try again": "Vyskytla sa chyba na serveri. Skúste to prosím znova",
  "And that's it!": "A to je všetko!",
  "anexo información": "Príloha informácií",
  "Applied filters": "Aplikované filtre",
  "Apply": "Aplikovať",
  "apply date filter": "aplikovať dátumový filter",
  "Apply selection": "Aplikovať výber",
  "Are you sure to close the editor?": "Ste si istí, že chcete zavrieť editor?",
  "Are You sure?": "Ste si istí?",
  "area": "plocha",
  "Area": "Plocha",
  "ATR": "Celkový obnoviteľný cukor",
  "author": "Autor",
  "Automatic harvest": "Automatická žatva",
  "auxiliary files": "Pomocné súbory",
  "back": "späť",
  "bajo estrés hídrico": "Nízky vodný stres",
  "bajo pot prod": "Nízky potenciál produkcie",
  "Base temperature": "Základná teplota",
  "baseLayer": "Základná vrstva",
  "Basic": "Základné",
  "basic season parameters": "základné parametre sezóny",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"oko na stvole\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"oko na stvole\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"oko na stvole\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"oko na stvole\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"oko na stvole\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Oba krajné body rezného riadku musia byť mimo polygonu/kruhu",
  "Buried": "Zahrabané",
  "calibre": "Kaliber",
  "camera error message": "Zdá sa, že je problém s povolením kamery",
  "camera_unavailable": "kamera nie je dostupná",
  "Can't modify field with active planet subscription": "Nemožno upraviť pole s aktívnym predplatným planéty",
  "cancel": "Zrušiť",
  "cane_num": "Počet trstín",
  "cantidad": "Množstvo",
  "capa base": "Základná vrstva",
  "Capture screenshot": "Zachytiť snímku obrazovky",
  "Change opacity": "Zmeniť priehľadnosť",
  "Choose your layer": "Vyberte svoju vrstvu",
  "Choose your tool": "Vyberte svoj nástroj",
  "Circle Drawing": "Kreslenie kruhu",
  "class": "Trieda",
  "Class values must be numerical": "Hodnoty tried musia byť číselné",
  "classes": "Triedy",
  "classes number": " Počet tried",
  "clear": "Vyčistiť",
  "Clear all": "Vyčistiť všetko",
  "clear form": "vyčistiť formulár",
  "clear selected fields": "Vyčistiť vybrané polia",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Kliknutím na toto tlačidlo môžete prepínať viditeľnosť vybranej vrstvy.",
  "clientDeviation": "Odchýlka klienta",
  "Close": "Zavrieť",
  "Close editor": "Zavrieť editor",
  "Close selection": "Zavrieť výber",
  "Close the temporal Comparison": "Zavrieť časové porovnanie",
  "close_selected": "Zavrieť vybrané",
  "closed": "zatvorené",
  "cloud": "Oblak",
  "Cloud Coverage": "Oblačnosť",
  "comment": "komentár",
  "comp. temporal target": "Časový cieľ komp.",
  "comp. temporal variabilidad": "Časová variabilita komp.",
  "comparación de conteo": "Porovnanie počtu (%)",
  "comparación de estado hídrico": "Porovnanie vodného stavu (%)",
  "comparación de estado hídrico drone": "Porovnanie vodného stavu - Drone (%)",
  "comparación de estado hídrico sat": "Porovnanie vodného stavu - SAT (%)",
  "comparación de estimación de producción": "Porovnanie odhadu produkcie (%)",
  "comparación de fósforo": "Porovnanie fosforu (%)",
  "comparación de mapa de alertas": "Porovnanie mapy varovaní (%)",
  "comparación de mapa de faltas": "Porovnanie mapy chýb (%)",
  "comparación de media de conteo": "Porovnanie priemeru počtu (%)",
  "comparación de media de estado hídrico": "Porovnanie priemeru vodného stavu (%)",
  "comparación de media de fósforo": "Porovnanie priemeru fosforu (%)",
  "comparación de media de mapa de alertas": "Porovnanie priemeru mapy varovaní (%)",
  "comparación de media de mapa de faltas": "Porovnanie priemeru mapy chýb (%)",
  "comparación de media de nitrógeno": "Porovnanie priemeru dusíka (%)",
  "comparación de media de potasio": "Porovnanie priemeru draslíka (%)",
  "comparación de media de producción total": "Porovnanie priemeru celkovej produkcie (%)",
  "comparación de media de rendimiento graso": "Porovnanie priemeru tukového výkonu (%)",
  "comparación de medias de estimación de producción": "Porovnanie priemerov odhadu produkcie (%)",
  "comparación de medias de ndvi": "Porovnanie priemerov NDVI (%)",
  "comparación de medias de proteína": "Porovnanie priemerov proteínu (%)",
  "comparación de medias de variabilidad": "Porovnanie priemerov variabilnosti (%)",
  "comparación de ndvi": "Porovnanie NDVI (%)",
  "comparación de nitrógeno": "Porovnanie dusíka (%)",
  "comparación de nitrógeno relativo": "Porovnanie relatívneho dusíka(%)",
  "comparación de potasio": "Porovnanie draslíka (%)",
  "comparación de producción total": "Porovnanie celkovej produkcie (%)",
  "comparación de proteína": "Porovnanie proteínu (%)",
  "comparación de rendimiento graso": "Porovnanie tukového výkonu (%)",
  "comparación de sacarosa": "Porovnanie sacharózy",
  "comparación de variabilidad": "Porovnanie variabilnosti (%)",
  "confirm": "Potvrdiť",
  "Congratulations! Your field has been created.": "Gratulujeme! Vaše pole bolo vytvorené.",
  "Congratulations! Your field has been edited.": "Gratulujeme! Vaše pole bolo upravené.",
  "Contact": "Kontakt",
  "Contenido de sacarosa ( % )": "Obsah sacharózy ( % )",
  "conteo - pdf rgb": "počet - pdf rgb",
  "conteo - shps": "počet - shps",
  "conteo de individuos": "Počet jedincov",
  "conteo lechugas": "Počet šalátov",
  "continue": "pokračovať",
  "CORN": "KUKURICA",
  "correction": "Korekcia",
  "Corte nitrogeno": "Rez dusíka",
  "cost": "Cena",
  "count (units)": "Počet (jednotky)",
  "Counting": "Počítanie",
  "create": "vytvoriť",
  "Create New Field": "Vytvoriť nové pole",
  "CREATE_RANGES": "Rozsahy rovnakej veľkosti budú generované podľa minimálnej a maximálnej hodnoty obrázka. V prípadoch, keď je obrázok veľmi homogénny, pravdepodobne nebude viditeľná žiadna diferenciácia.",
  "created": "vytvorené",
  "crecimiento cultivo": "Rast plodiny",
  "Crop": "Plodina",
  "Crop type": "Typ plodiny",
  "CROP_INSPECTION": "Inšpekcia plodín",
  "cultive": "Plodina",
  "Cultivo": "Plodina",
  "current season": "aktuálna sezóna",
  "current selection could take a moment to process": "Aktuálny výber by mohol chvíľu trvať spracovanie",
  "Customer": "Zákazník",
  "customerProductionEstimation": "Odhad produkcie zákazníka",
  "customers": "Zákazníci",
  "cut": "Rez",
  "Cutter": "Rezačka",
  "dashboard": "Panel",
  "date": "Dátum",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "delete": "zmazať",
  "Delete": "Zmazať",
  "Delete downloaded report": "Zmazať stiahnutú správu",
  "delete downloaded sample": "zmazať stiahnutú vzorku",
  "Delete field": "Zmazať pole",
  "delete layers?": "zmazať vrstvy?",
  "delete sample": "zmazať vzorku",
  "delete sample edition": "zmazať edíciu vzorky",
  "DeleteField": "Pole bolo zmazané",
  "Deliverables tutorial": "Návod: dodávky",
  "Description": "Popis",
  "Deselect": "Zrušiť výber",
  "deselect all": "Zrušiť výber všetkého",
  "deviation": "Odchýlka",
  "Did you forget your password ?": "Zabudli ste heslo?",
  "disponible en visor": "Dostupné v prehliadači",
  "do you want to delete": "chcete zmazať",
  "documentation": "Dokumentácia",
  "don't show again": "už nezobrazovať",
  "Done": "Hotovo",
  "dosificación genérica": "Generické dávkovanie",
  "Dosis Media Cliente": "Priemerná dávka klienta",
  "Dosis media Propuesta": "Navrhovaná priemerná dávka",
  "dosis riego": "Dávka zavlažovania",
  "Download": "Stiahnuť",
  "download failed": "Nasledujúce stiahnutia sa nepodarilo dokončiť",
  "Download list": "Zoznam na stiahnutie",
  "Download selected fields samples": "Stiahnuť vzorky vybraných polí",
  "download selected geometries": "Stiahnuť vybrané geometrie",
  "download values": "Stiahnuť hodnoty",
  "downloaded": "stiahnuté",
  "downloading layers": "sťahovanie vrstiev",
  "Drag and drop your layers to arrange them in the list.": "Potiahnite a pustite svoje vrstvy, aby ste ich usporiadali v zozname.",
  "Drag Fields": "Potiahnite polia",
  "Drag your layers": "Potiahnite svoje vrstvy",
  "Draw Field": "Nakresliť pole",
  "dron": "Drón",
  "Dron sectores": "Sektory drónov",
  "drop here": "pustiť tu",
  "Dry": "Suchý",
  "dry matter": "Suchá hmota",
  "dryMatterField": "Suchá hmota v poli",
  "dryMatterLaboratory": "Suchá hmota v laboratóriu",
  "dsm": "DSM",
  "earliest harvest date": "Najskorší dátum žatvy",
  "edit": "editovať",
  "Edit Field": "Editovať pole",
  "Edit geometry": "Editovať geometriu",
  "edit range": "editovať rozsah",
  "Edit values": "Editovať hodnoty",
  "ej 100": "Ex 100",
  "End Date": "Dátum ukončenia",
  "Equal Interval": "Rovnomerný Interval",
  "Equidistant ranges": "Rovnomerne vzdialené rozsahy",
  "error fetching classes": "Žiadne informácie o triedach pre aktuálny výber. Skúste inú kombináciu poľa/dátumu.",
  "estado hídrico": "Vodný stav",
  "estado hídrico (ndmi)": "Vodný stav (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Odhad",
  "estimación producción": "Odhad produkcie",
  "Estimacion produccion ( Tn / Ha )": "Odhad produkcie ( Tn / Ha )",
  "estimación producción cualitativa": "Kvalitatívny odhad produkcie",
  "estimación proteína": "Odhad proteínu",
  "Estimacion proteina ( % )": "Odhad proteínu ( % )",
  "estimación sacarosa": "Odhad sacharózy",
  "estimación sacarosa ": "Odhad sacharózy",
  "estimación sacarosa cualitativa": "Kvalitatívny odhad sacharózy",
  "Estimacion Total ( Kg )": "Celkový odhad ( Kg )",
  "estimation gauging": "Odhad merania",
  "estrategia de dosificacion": "Stratégia dávkovania",
  "estrés hídrico (lws)": "Vodný stres (LWS)",
  "evolución de NDVI": "Evolúcia NDVI",
  "evolution": "Evolúcia",
  "Ex : comment": "Ex : komentár",
  "Ex : field R02": "Ex : pole R02",
  "Ex : Tresbolillos, row, tree": "Ex : Tresbolillos, riadok, strom",
  "expected_final_weight": "Očakávaná konečná hmotnosť",
  "external_id": "Externé ID",
  "extra parameters": "extra parametre",
  "falhas carreadores": "Chyby nosičov",
  "falhas internas": "Interné chyby",
  "falhas terraços": "Chyby terás",
  "fat performance": "Tukový výkon",
  "faults map": "Mapa chýb",
  "fdn": "Fdn",
  "Fecha de vuelo": "Dátum letu",
  "Fecha Plantacion": "Dátum výsadby",
  "Feddan": "Feddan",
  "fertilization strategy": "Stratégia hnojenia",
  "Fertilizer recommendation": "Odporúčanie hnojiva",
  "field": "pole",
  "Field Name": "Názov poľa",
  "Field without geometry": "Pole bez geometrie",
  "FIELD_FILTER": "Filtre polí",
  "field_name": "Názov poľa",
  "FIELD_TEMPORAL_EVOLUTION": "Časová evolúcia poľa",
  "fields": "polia",
  "Fields without geometries": "Polia bez geometrií",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Súbor nemôže byť vygenerovaný kvôli veľkému množstvu mrakov. Prosím, vyberte iný dátum",
  "File generator": "Generátor súborov",
  "File name": "Názov súboru",
  "file type": "Typ súboru",
  "file-is-not-available-in-selected-language": "Tento súbor nie je dostupný vo vybranom jazyku. Generované v angličtine.",
  "Fill plantation date of current field, showing one year of historic data": "Vyplňte dátum výsadby aktuálneho poľa, zobrazuje sa jeden rok historických údajov",
  "Fill the field form": "Vyplňte formulár poľa",
  "Fill the form to apply for all our services": "Vyplňte formulár na požiadanie o všetky naše služby",
  "filtered field": "filtrované pole",
  "filtered fields": "filtrované polia",
  "Filters": "Filtre",
  "final_weight": "Konečná hmotnosť",
  "fitopatógenos": "Fytopatogény",
  "Flight Slider": "Posúvač letu",
  "FLIGHT_FILTER": "Časové porovnanie",
  "flights type": "Typy letov",
  "fmsavi": "FMSAVI",
  "for everyone": "pre všetkých",
  "for me": "pre mňa",
  "forecasts": "Predpovede",
  "formulario de abonado": "Formulár hnojenia",
  "formulario de dosis": "Formulár dávok",
  "fósforo foliar": "Listový fosfor",
  "fr": "Fr",
  "Fraction": "Zlomok",
  "free": "zadarmo",
  "from": "Od",
  "fruits_100_weight": "Hmotnosť 100 ovocí",
  "fruits_actual1_weight": "Aktuálna hmotnosť ovocia",
  "Fuente de informacion": "zdroj",
  "gallery": "galéria",
  "gauging date": "Dátum merania",
  "generation error": "chyba generovania",
  "geometry": "geometria",
  "get a free trial": "získajte bezplatnú skúšobnú verziu",
  "Get back to editor": "Vráťte sa do editora",
  "global files": "Stiahnite si súbory predspracovania, školenia a predpovede vo formátoch PDF a CSV",
  "global layer": "Globálna vrstva",
  "gm_cut_number": "Číslo rezu",
  "gm_data_generation": "Satelitný dátum (týždne)",
  "gm_day_cycle": "Dni pestovania",
  "gm_harvest_estimated_date": "Odhadovaný dátum žatvy (týždne)",
  "gm_hemav_atr_estimation": "Predpoveď TRS",
  "gm_hemav_pol_estimation": "Predpoveď polarizácie",
  "gm_hemav_prodph_estimation": "Predpoveď výnosu",
  "gm_hemav_realprodph_lastseason": "Aktuálny výnos z predchádzajúcej sezóny",
  "gm_hemav_sac_estimation": "Predpoveď sacharózy",
  "gm_luvi_date_generation": "Satelitný dátum - LUVI (týždne)",
  "gm_luvi_day_cycle": "Dni pestovania (NDVI-LUVI)",
  "gm_luvi_mean": "Vegetatívny rast (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilita (NDVI-LUVI)",
  "gm_lws_mean": "Vodný stres (LWS)",
  "gm_ndmi_date_generation": "Satelitný dátum - NDMI (týždne)",
  "gm_ndmi_day_cycle": "Dni pestovania (NDMI)",
  "gm_ndre_date_generation": "Satelitný dátum - NDRE (týždne)",
  "gm_ndre_day_cycle": "Dni pestovania (NDRE)",
  "gm_season_label": "Označenie sezóny",
  "gm_sub_type_name": "Odroda",
  "gm_type_name": "Plodina",
  "go to field": "Prejsť na pole",
  "grid": "mriežka",
  "harvest date": "Dátum žatvy",
  "harvest planning": "Plánovanie žatvy",
  "HARVEST_PLANNING_TOOL": "Nástroj na plánovanie žatvy",
  "HARVEST_PREDICTION": "Predpoveď žatvy",
  "Hectare": "Hektár",
  "Hemav Default": "Predvolené Hemav",
  "HEMAV Default": "HEMAV Podrazumevano",
  "Here, you can play with the preview of your different layers.": "Tu môžete hrať s náhľadom svojich rôznych vrstiev.",
  "hide": "skryť",
  "Hide All Layers": "Skryť všetky vrstvy",
  "hide filter list": "skryť zoznam filtrov",
  "Hide Global Layer": "Skryť globálnu vrstvu",
  "Hide Measure Tools": "Skryť nástroje merania",
  "high": "Vysoké",
  "high level": "Vysoká úroveň",
  "high need": "Vysoká potreba",
  "higher clay content": "Vyšší obsah ílu",
  "humedad de suelo - beta (%)": "Vlhkosť pôdy - Beta (%)",
  "humedad vegetativa (ndmi)": "Vegetatívna vlhkosť (NDMI)",
  "Hybrid View": "Hybridný pohľad",
  "hydric status": "Hydric Status - NDMI",
  "hydric status drone": "Hydric status - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "Ak nebola vykonaná listová vzorka, musíte zadať aktuálny dátum",
  "If you have two or more flights, you can compare them temporally using this tool.": "Ak máte dva alebo viac letov, môžete ich pomocou tohto nástroja porovnať v čase.",
  "If you have undone an action, you can press this button to redo it.": "Ak ste zrušili akciu, môžete stlačiť toto tlačidlo, aby ste ju znova vykonali.",
  "image": "obrázok",
  "Impacto estimado": "Odhadovaný dopad",
  "In this tab, you can download the generated PDFs.": "Na tejto karte môžete stiahnuť generované PDF súbory.",
  "individuals_num": "Počet jedincov",
  "infection": "Infekcia",
  "Information": "Informácie",
  "informe": "Správa",
  "integración con maquinaria": "Integrácia s mašinériou",
  "Invalid credentials": "Neplatné poverenia",
  "invalid date": "Neplatný dátum",
  "Invalid email": "Neplatný email",
  "invest": "Investovať",
  "Irrigation recommendation": "Odporúčanie zavlažovania",
  "Irrigation type": "Typ zavlažovania",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Bežne sa upravuje na určenie množstva hnojiva alebo semien, ktoré sa majú aplikovať na jednotku plochy.",
  "item_num": "Číslo položky",
  "Keep selection": "Zachovať výber",
  "label": "01.Štítok",
  "Label": "Štítok",
  "languaje": "Jazyk",
  "last sample": "Posledná vzorka",
  "lat": "Zemepisná šírka",
  "layers": "Vrstvy",
  "layers estrés hídrico (lws)": "Vrstvy vodného stresu (LWS)",
  "layers status": "Stav vrstiev",
  "Legal limitation": "Právne obmedzenie",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"kruhová škvrna\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"kruhová škvrna\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"kruhová škvrna\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"kruhová škvrna\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"kruhová škvrna\" - g5",
  "letter only": "Len písmená",
  "linear meters": "Lineárne metre",
  "líneas de cultivo": "Línie pestovania",
  "list": "zoznam",
  "loading": "Načítavanie",
  "location": "Umiestnenie",
  "Log in": "Prihlásiť sa",
  "logout": "Odhlásiť sa",
  "lon": "Zemepisná dĺžka",
  "looks like its taking too long": "vyzerá to, že to trvá príliš dlho",
  "low": "Nízke",
  "low level": "Nízka úroveň",
  "low need": "Nízka potreba",
  "lower clay content": "Nižší obsah ílu",
  "LUVI variability": "Variabilita LUVI",
  "Machines Type": "Typy strojov",
  "main_macronutrient": "Hlavný makronutrient",
  "malas hierbas": "Buriny",
  "malas hierbas - hoja estrecha": "Buriny - úzke listy",
  "malas hierbas - hoja larga": "Buriny - široké listy",
  "malas hierbas - zonificado": "Buriny - zónované",
  "malas hierbas shps": "Buriny SHPs",
  "malas hierbas shps - hoja estrecha": "Buriny SHPs - úzke listy",
  "malas hierbas shps - hoja larga": "Buriny SHPs - široké listy",
  "malas hierbas shps - zonificado": "Buriny SHPs - zónované",
  "Manage report": "Spravovať správu",
  "max 2 years": "Max 2 roky",
  "max comparable fields": "Max porovnateľné polia",
  "maximo de variacion": "Maximálna variácia",
  "máximo vigor dos meses (ndvi)": "Maximálny rast dvoch mesiacov (NDVI)",
  "máximo vigor un año (ndvi)": "Maximálny rast jedného roka (NDVI)",
  "Maximun cloud coverage": "Maximálna oblačnosť",
  "mean": "Priemer",
  "Measures tool tutorial": "Návod: Nástroje merania",
  "Measures tools": "Nástroje merania",
  "media de faltas": "Priemerné chyby",
  "medias": "Médiá",
  "medio pot prod": "Stredný potenciál produkcie",
  "medium": "Stredné",
  "medium clay content": "Stredný obsah ílu",
  "medium level": "Stredná úroveň",
  "medium_weight_bigSize": "Priemerná hmotnosť veľkých plodov",
  "medium_weight_mediumSize": "Priemerná hmotnosť stredných plodov",
  "medium_weight_smallSize": "Priemerná hmotnosť malých plodov",
  "Mensual limit": "Mesačný limit",
  "Message": "Správa",
  "METEOROLOGICAL_STATISTICS": "Meteorologická štatistika",
  "mode": "Režim",
  "model type": "Typ modelu",
  "Move the slider": "Posuňte posúvač",
  "msavi": "MSAVI",
  "N_BUNCHES": "Počet zhlukov",
  "Name": "Názov",
  "nameless field": "Pole bez mena",
  "Natural Breaks (Jenks)": "Prírodné Prestávky (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "Reálny NDVI",
  "ndvi-curvas de nivel": "NDVI-Krивky úrovne",
  "Network request failed": "Chyba pripojenia",
  "new sample": "nová vzorka",
  "New sample created.": "Nová vzorka vytvorená.",
  "NITRO": "Dusík",
  "nitrogen chlorophyll (ndre)": "Dusík-Chlorofyl (NDRE)",
  "nitrogen_assimilable": "Asimilovateľný dusík",
  "nitrógeno foliar": "Listový dusík",
  "Nivel de estres": "Úroveň stresu",
  "no data": "Žiadne údaje",
  "no data available": "Žiadne dostupné údaje",
  "no data with current selection": "Žiadne údaje s aktuálnym výberom",
  "No fields selected": "Nie sú vybrané žiadne polia",
  "no fields to clear": "Žiadne polia na vyčistenie",
  "Not a user yet ?": "Ešte nie ste užívateľ?",
  "not enough data to display this chart": "Nedostatok údajov na zobrazenie tohto grafu",
  "Not supported by browser": "Nie je podporované prehliadačom",
  "notes": "poznámky",
  "number only": "Len čísla",
  "Numero vuelo": "Počet letov",
  "observations": "pozorovania",
  "offline access": "prístup offline",
  "offline mode": "Režim offline",
  "oil_yield": "Výnos oleja",
  "ok": "Ok",
  "Once you are done, click this button to save the changes.": "Keď skončíte, kliknite na toto tlačidlo, aby ste uložili zmeny",
  "open": "otvoriť",
  "Open": "Otvoriť",
  "Open selection": "Otvoriť výber",
  "open temporal comparison": "Otvoriť časové porovnanie",
  "open_selected": "Otvoriť vybrané",
  "optimize": "Optimalizovať",
  "or": "alebo",
  "Order by a - z": "Zoradiť od a - z",
  "Order by distance to the field": "Zoradiť podľa vzdialenosti k poľu",
  "Order by z - a": "Zoradiť od z - a",
  "ourDeviation": "Odchýlka Hemav",
  "ourProductionEstimation": "Odhad produkcie Hemav",
  "Parcela": "Parcela",
  "Password": "Heslo",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD strom",
  "pcd interpolado": "Interpolovaný PCD",
  "pcd row": "PCD riadok",
  "pdf": "PDF",
  "pending": "čakajúce",
  "phospohorus": "Fosfor",
  "phosporus": "Fosfor (ppm)",
  "photo": "foto",
  "photoid": "ID fotky",
  "photoId": "Fotografia",
  "picture_path": "Cesta k obrázku",
  "plague": "Mor",
  "planned dosage": "Plánovaná dávka",
  "Plant emitters": "Rastlinné vysielače",
  "plantation date": "Dátum výsadby",
  "Plantation date": "Dátum výsadby",
  "plantation_end_date_range": "Rozsah dátumu výsadby",
  "plantation_start_date_range": "Rozsah dátumu výsadby",
  "Plantations": "Plantáže",
  "plants": "Rastliny",
  "Plants distance": "Vzdialenosť rastlín",
  "plants_density_range": "Rozsah hustoty rastlín",
  "Please wait or order one to have data to visualized.": "Naplánujte let, aby ste získali výstupy pre toto pole",
  "Please, choose a field variety": "Prosím, vyberte odrodu poľa",
  "Please, choose the field owner": "Prosím, vyberte majiteľa poľa",
  "plural selected": "vybrané",
  "POL": "Polarizácia",
  "Polygon drawing": "Kreslenie polygónu",
  "positive number only": "Len kladné číslo",
  "positive number only and minor 100": "Len kladné číslo a menšie ako 100",
  "pot. horticolar 90": "pot. záhradnícke 90",
  "pot. otros cultivos 150": "pot. iné plodiny 150",
  "pot. otros cultivos 90": "pot. iné plodiny 90",
  "potasio foliar": "Listový draslík",
  "potencial productivo histórico (ndvi)": "Historický produkčný potenciál (NDVI)",
  "precio": "Cena",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Preddefinované rozsahy od HEMAV, ktoré sú všeobecne vhodné pre väčšinu polí.",
  "prediction": "Predpoveď",
  "preprocessing": "Predspracovanie",
  "Press this button to add a new field.": "Stlačte toto tlačidlo, aby ste pridali nové pole.",
  "Pressing this button lets you go back to the field view. ": "Stlačením tohto tlačidla sa vrátite do pohľadu poľa.",
  "Preview": "Náhľad",
  "price Kg": "Cena za kg",
  "primer vuelo": "Prvý let",
  "PROD": "Výnos na jednotku plochy",
  "producción esperada": "Očakávaná produkcia",
  "product Kg": "Produkt Kg",
  "product Kg is mandatory": "Produkt kg je povinný",
  "Production estimate": "Odhad produkcie",
  "production estimate (units)": "Odhad produkcie (Kg/Ha)",
  "profile": "Profil",
  "protein": "Proteín",
  "puccinia_sp_g1": "puccinia sp \"roya\" - g1",
  "puccinia_sp_g2": "puccinia sp \"roya\" - g2",
  "puccinia_sp_g3": "puccinia sp \"roya\" - g3",
  "puccinia_sp_g4": "puccinia sp \"roya\" - g4",
  "puccinia_sp_g5": "puccinia sp \"roya\" - g5",
  "Quick start with Azure": "Rýchly štart s Azure",
  "Random fertilization": "Náhodné hnojenie",
  "Random seedling": "Náhodné výsevy",
  "Range": "Rozsah",
  "Ranges adjusted to the index": "Rozsahy upravené podľa indexu",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Rozsahy rovnakej veľkosti budú generované podľa minimálnej a maximálnej hodnoty obrázka. V prípadoch, keď je obrázok veľmi homogénny, pravdepodobne nebude viditeľná žiadna diferenciácia.",
  "realProduction": "Reálna produkcia",
  "Recipients": "Príjemcovia",
  "recomendación abonado dosificado": "Predpis dávkovania hnojenia",
  "recomendación abonado zonificado": "Odporúčanie zónového hnojenia",
  "Recomendacion de riego": "Odporúčanie zavlažovania",
  "recomendación riego": "Odporúčanie zavlažovania",
  "Redo Action": "Zopakovať akciu",
  "Related date": "Súvisiaci dátum",
  "relative nitrogen": "Relatívny dusík",
  "Rendimiento graso ( % )": "Tukový výkon ( % )",
  "report": "Správa",
  "Report sent correctly": "Správa bola odoslaná správne",
  "report type": "Typ správy",
  "Report type": "Typ správy",
  "report_type_action_description": "Návrh liečby alebo akcií na aplikáciu",
  "report_type_annotation_description": "Diagnostika: Analýza v teréne",
  "report_type_inspection_description": "Analýza z VRSTIEV (uzavreté po analýze)",
  "report_type_result_description": "Analýza výsledkov aplikovaného riešenia alebo liečby.",
  "reporte agronómico": "Agronomická správa",
  "Reported by": "Nahlásené",
  "Reported fields": "Nahlásené polia",
  "reports manager": "Správy",
  "required": "požadované",
  "Required": "Požadované",
  "Requires 3 characters minimun": "Vyžaduje minimálne 3 znaky",
  "Reset": "Reset",
  "resiembra": "Presev",
  "resolution": "Rozlíšenie",
  "resolution (only SHP)": "Rozlíšenie (len pre SHP)",
  "retención de agua": "Zachytávanie vody",
  "retry": "opakovať",
  "rgb": "RGB",
  "richness": "Bohatstvo",
  "riego zonificado": "Zónované zavlažovanie",
  "riesgo malherbología": "Riziko burín",
  "Ruler": "Pravítko",
  "SAC": "Sacharóza na jednotku plochy",
  "saccharose estimate": "Odhad sacharózy",
  "sample": "vzorka",
  "sample type": "typ",
  "Sample was deleted.": "Vzorka bola zmazaná.",
  "Sample was successfully modified.": "Vzorka bola úspešne upravená.",
  "sample_creation_date": "Dátum vytvorenia",
  "samples": "vzorky",
  "satélite": "Satelit",
  "Satellite button tutorial": "Návod: Tlačidlo Satelit",
  "Satellite View": "Satelitný pohľad",
  "save": "Uložiť",
  "Save field": "Uložiť pole",
  "save_": "uložiť",
  "search": "Hľadať",
  "Search": "Hľadať",
  "season": "sezóna",
  "Season label": "Označenie sezóny",
  "season name": "názov sezóny",
  "SEASON_EVOLUTION": "Evolúcia sezóny",
  "Sector": "Sektor",
  "sectores target": "Cieľové sektory",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Vidíte? Teraz kliknutím na tlačidlo znova sa vrátite do satelitného pohľadu.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Druhý let",
  "Select": "Vybrať",
  "select a crop and sample": "Vyberte plodinu a vzorku",
  "select all": "Vybrať všetko",
  "Select all": "Vybrať všetko",
  "select at least one field": "Vyberte aspoň jedno pole",
  "select fields by": "Vybrať polia podľa",
  "select fields by variability range": "vyberte polia podľa rozsahu variability",
  "select fields to open temporal comparison": "Vyberte polia na otvorenie časového porovnania",
  "select one": "Vyberte jeden",
  "select one customer please": "Vyberte jedného zákazníka, prosím",
  "select one subtype please": "Vyberte jednu podskupinu, prosím",
  "select one type please": "Vyberte jeden typ, prosím",
  "select sample base layer and date": "vyberte základnú vrstvu vzorky a dátum",
  "select sample location": "vyberte umiestnenie vzorky",
  "select season": "vyberte sezónu",
  "selected": "vybrané",
  "Selected fields names": "Vybrané názvy polí",
  "Selected fields samples": "Vybrané vzorky polí",
  "Selection includes too much information, try selecting a shorter date range": "Výber obsahuje príliš veľa informácií, skúste vybrať kratší dátumový rozsah",
  "Send": "Poslať",
  "Send report": "Poslať správu",
  "settings": "Nastavenia",
  "Share fields report": "Zdieľať správu o poliach",
  "show all": "zobraziť všetko",
  "Show All Layers": "Zobraziť všetky vrstvy",
  "Show current season only": "Zobraziť len aktuálnu sezónu",
  "show fields with no data": "Zobraziť polia bez údajov",
  "show filter list": "zobraziť zoznam filtrov",
  "Show Global Layer": "Zobraziť globálnu vrstvu",
  "Show Measure Tools": "Zobraziť nástroje merania",
  "shp-tooltip": "Tieto čísla je možné upravovať podľa potrieb použitia a sú spojené s každou triedou geometrie v exportovanej tabuľke atribútov SHP. Bežne sa upravujú na stanovenie množstva hnojiva alebo semien, ktoré budú aplikované na jednotku plochy.",
  "singular selected": "vybrané",
  "Slice tool only works on Polygons and Circle": "Nástroj na rezanie funguje len na polygónoch a kruhu",
  "Slider Tool": "Posúvač",
  "soil": "Pôda",
  "soil_type": "Typ pôdy",
  "sólo para la generación de SHP": "Len pre generovanie SHP",
  "Sort": "Zoradiť",
  "sort by date": "zoradiť podľa dátumu",
  "Start date": "Dátum začiatku",
  "static layers": "statické vrstvy",
  "Statistics": "Štatistiky",
  "status": "Stav",
  "stem_num": "Počet stoniek",
  "Streets distance": "Vzdialenosť ulíc",
  "Subtype": "Podtyp",
  "Suc/Ha": "Suc/Ha",
  "sum_of_bases": "Súčet základov",
  "Superficie ( Ha )": "Plocha ( Ha )",
  "Superficie Sector ( Ha )": "Plocha sektora ( Ha )",
  "surfaceUnit": "Jednotka plochy",
  "switch between all samples and current season samples": "prepínať medzi všetkými vzorkami a vzorkami aktuálnej sezóny",
  "Switch to hybrid map": "Prepnúť na hybridnú mapu",
  "Switch to Mapbox": "Prepnúť na satelitnú mapu 2",
  "Switch to satellite map": "Prepnúť na satelitnú mapu",
  "Switch to Terrain map": "Prepnúť na terénny mapu",
  "target date": "Cieľový dátum",
  "target multiparcela": "Cieľové multifield",
  "tell us": "povedzte nám",
  "Temporal Comparison tutorial": "Návod: Časové porovnanie",
  "textura del suelo": "Textúra pôdy",
  "The date should be in DD/MM/YYYY format": "Referenčný dátum musí byť platný vo formáte DD/MM/YYYY",
  "The distance must be in meters": "Vzdialenosť musí byť v metroch",
  "the extra parameters will be removed": "extra parametre budú odstránené",
  "the following layers are no longer available in offline mode, download again if necessary": "nasledujúce vrstvy už nie sú dostupné v režime offline, stiahnite ich znova, ak je to potrebné",
  "The production must be numeric/decimal": "Produkcia musí byť číselná/desatinná",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "Pravý stĺpec zobrazuje celkové množstvo, ktoré sa má aplikovať podľa hodnoty zadanej pre triedu, a celkové množstvo na parcelu v dolnej časti.",
  "The sample location must be inside one of the fields.": "Umiestnenie vzorky musí byť vo vnútri jedného z polí.",
  "There are no samples to download": "Nie sú žiadne vzorky na stiahnutie",
  "There is no operated flight on this field.": "Na tomto poli nebol prevádzkovaný žiadny let",
  "Thermal Accumulation": "Tepelné nahromadenie",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Tieto čísla je možné upraviť podľa potrieb aplikácie a sú priradené každej geometrie v atribútovej tabuľke exportovaného SHP.",
  "This action will delete the field and all its related data": "Táto akcia zmaze pole a všetky súvisiace údaje",
  "this action will modify your field selection": "Táto akcia zmení váš výber poľa",
  "this action will open field view and lose current field selection": "Táto akcia otvorí pohľad poľa a stratí aktuálny výber poľa",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Toto tlačidlo umožňuje kresliť čiary alebo polygóny na meranie vzdialenosti alebo plochy.",
  "This button allows you to switch to the hybrid view.": "Toto tlačidlo umožňuje prepnúť na hybridný pohľad.",
  "This can take a long time": "Toto môže trvať dlho",
  "This field is not downloaded.": "Toto pole nie je stiahnuté.",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Tento nástroj umožňuje rezať polygóny. Použite jednotlivé kliknutia na kreslenie čiary a dvojklik na dokončenie kreslenia.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Tento nástroj umožňuje kresliť polygóny. Skúste nakresliť jednoduchý. Uistite sa, že ho uzavriete!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Tento nástroj umožňuje merať vzdialenosť medzi sadou bodov. Použite jednotlivé kliknutia na nastavenie bodov a dvojklik na dokončenie kreslenia.",
  "Those fields are mandatory": "Tieto polia sú povinné",
  "TIME_COMPARISON_DROP_ZONE": "Pustiť tu...",
  "TINTA BARROCA": "TINTA BARROCA",
  "TINTA FRANCISCA": "TINTA FRANCISCA",
  "TINTO": "ČERVENÉ",
  "TINTO CÃO": "ČERVENÉ CÃO",
  "tipo de dosificacion": "Typ dávkovania",
  "to": "Do",
  "today": "dnes",
  "todos": "Všetci",
  "tons of cane - annual vision": "Tóny cukrovej trstiny - ročný pohľad",
  "Total": "Celkový",
  "total production": "Celková produkcia (Kg)",
  "total_fruits_bigSize": "Celkové veľké ovocie",
  "total_fruits_mediumSize": "Celkové stredné ovocie",
  "total_fruits_smallSize": "Celkové malé ovocie",
  "total_weight": "Celková hmotnosť",
  "total_weight_bigSize": "Celková hmotnosť veľkého ovocia",
  "total_weight_canes": "Celková hmotnosť trstiny",
  "total_weight_mediumSize": "Celková hmotnosť stredného ovocia",
  "total_weight_smallSize": "Celková hmotnosť malého ovocia",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Školenie",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Skúste našu mobilnú aplikáciu pre lepší zážitok",
  "type": "Plodina",
  "Type": "Typ plodiny",
  "type here your notes": "tu napíšte svoje poznámky",
  "Type your text": "Napíšte svoj text",
  "type_sampler": "Typ vzorky",
  "Undo Action": "Vrátiť akciu",
  "unidades": "Jednotky",
  "unitSystem": "Systém jednotiek",
  "Unknown": "Neznáme",
  "unnamed field": "Nepomenované pole",
  "unsaved changes will be lost": "Neuložené zmeny budú stratené",
  "update sample": "aktualizovať vzorku",
  "updated": "aktualizované",
  "Use this buttons to select which layer to compare.": "Použite tieto tlačidlá na výber vrstvy na porovnanie.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Použite tento nástroj na porovnanie dvoch letov v čase. Na začiatok presuňte jeden z letov do toho, s ktorým ho chcete porovnať.",
  "Use this tool to freely move the created polygons.": "Použite tento nástroj na voľné presúvanie vytvorených polygónov.",
  "used": "použité",
  "Username": "Používateľské meno",
  "users": "Používatelia",
  "Value": "Hodnota",
  "variabilidad multiparcela": "Multifield variabilita",
  "variabilidad parcela (vra)": "Variabilná sadzba oblasti (VRA)",
  "variability": "Variabilita",
  "Variedad": "Podplodina",
  "vegetative growth": "Vegetatívny rast",
  "vegetative growth per day": "Vegetatívny rast za deň",
  "vegetative growth variability": "Variabilita vegetatívneho rastu",
  "Ver detalles": "Zobraziť detaily",
  "very high": "Veľmi vysoké",
  "very high level": "Veľmi vysoká úroveň",
  "very low": "Veľmi nízke",
  "very low level": "Veľmi nízka úroveň",
  "view": "pohľad",
  "vigor": "VIGOR",
  "vigor a nivel árbol": "vigor / rastlina",
  "vigor acumulado dos meses (ndvi)": "Akumulovaný vigor dvoch mesiacov (NDVI)",
  "vigor acumulado un año (ndvi)": "Akumulovaný vigor jedného roka (NDVI)",
  "vigor vegetativo": "Vegetatívny vigor",
  "vigor vegetativo - pcd": "PCD - Vegetatívny vigor",
  "vigor vegetativo (ndvi)": "Vegetatívny vigor (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vegetatívny vigor (NDVI) - skoré fázy",
  "vigor zonificado": "Zónovaný vigor",
  "VINHÃO": "VINHÃO",
  "Visibility": "Viditeľnosť",
  "Vulnerable area": "Zraniteľná oblasť",
  "wait": "počkať",
  "warning": "varovanie",
  "warning map": "mapa varovania",
  "Water level": "Vodná hladina",
  "We are processing the data for your flight. Results will be available soon.": "Spracúvame údaje vášho letu. Výsledky budú čoskoro dostupné.",
  "weeds area": "Oblasť burín",
  "week": "týždeň",
  "weight_1": "Hmotnosť 1",
  "weight_2": "Hmotnosť 2",
  "weight_3": "Hmotnosť 3",
  "weight_4": "Hmotnosť 4",
  "weight_5": "Hmotnosť 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "S týmto nástrojom môžete kresliť kruhy. Kliknite na umiestnenie stredu a znova kliknite na výber polomeru.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "S týmto nástrojom môžete nakresliť polygón s 3 alebo viac bodmi na meranie jeho plochy.",
  "without warning": "bez varovania",
  "Wrong credentials": "Nesprávne poverenia",
  "year": "rok",
  "years": "Roky",
  "You can change here the information of the new field.": "Tu môžete zmeniť informácie o novom poli.",
  "You can move this bar to compare the layers temorally.": "Tento panel môžete presúvať na porovnanie vrstiev v čase.",
  "You can only compare dates from the same sources": "Môžete porovnávať len dátumy z rovnakých zdrojov",
  "You can use this button at any moment to undo your last actions.": "Toto tlačidlo môžete kedykoľvek použiť na vrátenie svojich posledných akcií.",
  "You can use this slider to change the opacity of the selected layer.": "Týmto posúvačom môžete zmeniť priehľadnosť vybranej vrstvy.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Nemôžete vytvoriť pole, pretože nemáte žiadnych zákazníkov. Prosím, požiadajte nás o pridanie.",
  "You must enter a decimal": "Musíte zadať desatinné číslo",
  "You must enter an integer": "Musíte zadať celé číslo",
  "You must select an irrigation type": "Musíte vybrať typ zavlažovania",
  "You must type a field name": "Musíte zadať názov poľa",
  "You must type a text": "Musíte napísať text",
  "Your field has been successfully deleted.": "Vaše pole bolo úspešne zmazané.",
  "Your LAYERS version has expired. Click to update.": "Vaša verzia LAYERS vypršala. Kliknite na aktualizáciu."
}
