import React, { useRef, useState } from 'react'
import StyledDesktopDatePicker from '../UIComponents/Calendars/StyledDesktopDatePicker'
import SelectedFieldsCalendarLegend from './SelectedFieldsCalendarLegend'
import TemporalComparison from './TemporalComparison'
import styled from 'styled-components'
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { groupBy, pickBy } from 'lodash'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import { MAX_CLOUD_COVERAGE } from '../../constants'
import StyledSlider from '../UIComponents/StyledSlider'
import themeColors from '@layers-frontend/commons/styles/themeColors'

// taken from: https://github.com/clauderic/dnd-kit/blob/master/packages/modifiers/src/utilities/restrictToBoundingRect.ts
// based on: https://docs.dndkit.com/api-documentation/modifiers#building-custom-modifiers
function restrictToBoundingRect(transform, rect, boundingRect) {
  const value = {
    ...transform
  }

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top
  } else if (rect.bottom + transform.y >= boundingRect.top + boundingRect.height) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left
  } else if (rect.right + transform.x >= boundingRect.left + boundingRect.width) {
    value.x = boundingRect.left + boundingRect.width - rect.right
  }

  return value
}

const restrictToElement = ref => ({ draggingNodeRect, transform }) => {
  if (!draggingNodeRect || !ref || !ref.current) {
    return transform
  }

  const { current } = ref
  const boundingBox = current.getBoundingClientRect()

  return restrictToBoundingRect(transform, draggingNodeRect, boundingBox)
}

const PaddingWrapper = styled.div`
  padding: 0 30px 0 40px;
  margin-top: -15px;
`

const SliderWrapper = styled.div`
  padding: 0 40px 10px;
`

const labelStyle = {
  color: themeColors.vomitColor,
  fontSize: 14
}

function SelectedFieldsCalendar({
  activeDate,
  activeDateFlightGroups,
  calendarDots,
  compareToFlightGroup,
  flightGroupInfoFilteredByDate,
  flightGroups,
  isLoading,
  orderedFlightGroupDates,
  selectedFlightGroupDate,
  selectFields,
  selectFlightGroup,
  setActiveCalendarDate,
  snapshots,
  tempComparisonDate,
  droneFlightsSnapshotsDates,
  cloudCoverage,
  setCloudCoverage,
  hasRoleSHCalendar
}) {
  const [isComparable, setIsComparable] = useState(false)
  const [droneSnapshotsMode, setDroneSnapshotsMode] = useState(false)

  const hasDroneSnapshots = !isEmpty(droneFlightsSnapshotsDates)
  const handleSetDroneSnapshotsMode = mode => {
    if (hasDroneSnapshots) {
      setDroneSnapshotsMode(mode)
    }
  }

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10
    }
  })
  const sensors = useSensors(mouseSensor)
  const constrainingContainer = useRef()
  const restrict = restrictToElement(constrainingContainer)

  const onDragStart = ({ active }) => {
    const tempDateFlightGroups = active.data.current.currentDateFlightGroups
    const flightGroupsBySources = groupBy([...activeDateFlightGroups, ...tempDateFlightGroups], 'source')
    const availableFlightGroupsBySources = pickBy(flightGroupsBySources, flightGroups => size(flightGroups) === 2)
    setIsComparable(size(availableFlightGroupsBySources) > 0)
  }

  const onDragEnd = ({ over, active }) => {
    if (over && isComparable) {
      compareToFlightGroup(active.id)
    }
  }

  return (
    <div ref={constrainingContainer}>
      <SelectedFieldsCalendarLegend
        hasDroneSnapshots={hasDroneSnapshots}
        droneSnapshotsMode={droneSnapshotsMode}
        handleSetDroneSnapshotsMode={handleSetDroneSnapshotsMode}
      />
      <DndContext sensors={sensors} modifiers={[restrict]} onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <StyledDesktopDatePicker
          selectFields={selectFields}
          flightGroups={flightGroups}
          selectedFlightGroupDate={selectedFlightGroupDate}
          snapshots={snapshots}
          isLoading={isLoading}
          orderedFlightGroupDates={orderedFlightGroupDates}
          flightGroupInfoFilteredByDate={flightGroupInfoFilteredByDate}
          selectFlightGroup={selectFlightGroup}
          calendarDots={calendarDots}
          activeDate={activeDate}
          setActiveDate={setActiveCalendarDate}
          droneFlightsSnapshotsDates={droneFlightsSnapshotsDates}
          droneSnapshotsMode={droneSnapshotsMode}
          cloudCoverage={cloudCoverage}
        />
        <PaddingWrapper>
          <TemporalComparison
            isComparable={isComparable}
            selectedFlightGroupDate={selectedFlightGroupDate}
            tempComparisonFlightGroupDate={tempComparisonDate}
          />
        </PaddingWrapper>
      </DndContext>
      {hasRoleSHCalendar ? null : (
        <SliderWrapper>
          <StyledSlider
            label={'Cloud Coverage'}
            onChange={setCloudCoverage}
            defaultValue={cloudCoverage}
            step={1}
            minValue={0}
            maxValue={MAX_CLOUD_COVERAGE}
            unit={'%'}
            fullWidth={true}
            labelStyle={labelStyle}
          />
        </SliderWrapper>
      )}
    </div>
  )
}

export default SelectedFieldsCalendar
