// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Дуже низький",
  "% Mapa alertas": "% Карта попереджень",
  "% Media Fosforo": "% Середній фосфор",
  "% Media Nitrogeno": "% Середній азот",
  "% Media Potasio": "% Середній калій",
  "% resiembra": "% Пересів",
  "0.10 Low": "0.10 Низький",
  "0.20 Very Low": "0.20 Дуже низький",
  "0.25 Very Low ": "0.25 Дуже низький",
  "0.30 Low": "0.30 Низький",
  "0.30 Medium": "0.30 Середній",
  "0.40  Low": "0.40 Низький",
  "0.40 Medium": "0.40 Середній",
  "0.45 High": "0.45 Високий",
  "0.50 High": "0.50 Високий",
  "0.55 Medium": "0.55 Середній",
  "0.65 Very High": "0.65 Дуже високий",
  "0.70 High": "0.70 Високий",
  "0.85 Very High": "0.85 Дуже високий",
  "abonado de cobertera": "Підживлення покривного",
  "abonado de fondo": "Базове удобрення",
  "abonado dosificado": "Дозоване удобрення",
  "abonado tasa fija": "Удобрення фіксованою ставкою",
  "abonado zonificado": "Зоноване удобрення",
  "accumulated vegetative growth": "Накопичене вегетативне зростання",
  "Acre": "Акр",
  "active": "активний",
  "Add at least one recipient (+)": "Додайте принаймні одного отримувача (+)",
  "Add Field Button": "Кнопка додавання поля",
  "Add Field Tutorial": "Інструкція з додавання поля",
  "add location": "додати місцезнаходження",
  "Add new sample": "Додати новий зразок",
  "add photo": "додати фото",
  "added to pending": "додано до очікуваних",
  "Advanced": "Розширений",
  "advanced season parameters": "розширені параметри сезону",
  "afección": "Ураження",
  "afección extensivo": "Масове ураження",
  "Affected Surface": "Уражена поверхня",
  "After filling the form, you can draw the field": "Після заповнення форми ви можете намалювати поле",
  "agrodelivery": "Агродоставка",
  "all": "всі",
  "ALMENDRA": "Мигдаль",
  "alto estrés hídrico": "Високий водний стрес",
  "alto pot prod": "Висока потенційна продуктивність",
  "ambiente": "Середовище",
  "An error occured": "Сталася помилка",
  "An error occurred from the server. Please try again": "Сталася помилка на сервері. Будь ласка, спробуйте ще раз",
  "And that's it!": "І ось і все!",
  "anexo información": "Додаткова інформація",
  "Applied filters": "Застосовані фільтри",
  "Apply": "Застосувати",
  "apply date filter": "застосувати фільтр дати",
  "Apply selection": "Застосувати вибір",
  "Are you sure to close the editor?": "Ви впевнені, що хочете закрити редактор?",
  "Are You sure?": "Ви впевнені?",
  "area": "площа",
  "Area": "Площа",
  "ATR": "Загальний відновлюваний цукор",
  "author": "автор",
  "Automatic harvest": "Автоматичний збір",
  "auxiliary files": "допоміжні файли",
  "back": "назад",
  "bajo estrés hídrico": "Низький водний стрес",
  "bajo pot prod": "Низька потенційна продуктивність",
  "Base temperature": "Базова температура",
  "baseLayer": "Базовий шар",
  "Basic": "Базовий",
  "basic season parameters": "базові параметри сезону",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"око пляма\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"ко пляма\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"око пляма\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"око пляма\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"око пляма\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Обидві крайні точки лінії зрізу повинні бути зовні полігона/кола",
  "Buried": "Закопаний",
  "calibre": "Калібр",
  "camera error message": "Здається, є проблема з дозволом камери",
  "camera_unavailable": "камера недоступна",
  "Can't modify field with active planet subscription": "Неможливо змінити поле з активною підпискою на планету",
  "cancel": "скасувати",
  "cane_num": "Кількість тростин",
  "cantidad": "Кількість",
  "capa base": "Базовий шар",
  "Capture screenshot": "Зробити знімок екрана",
  "Change opacity": "Змінити непрозорість",
  "Choose your layer": "Виберіть ваш шар",
  "Choose your tool": "Виберіть ваш інструмент",
  "Circle Drawing": "Малювання круга",
  "class": "Клас",
  "Class values must be numerical": "Значення класів мають бути числовими",
  "classes": "Класи",
  "classes number": " Кількість класів",
  "clear": "Очистити",
  "Clear all": "Очистити все",
  "clear form": "очистити форму",
  "clear selected fields": "Очистити вибрані поля",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Клацнувши цю кнопку, ви можете перемикати видимість вибраного шару.",
  "clientDeviation": "Відхилення клієнта",
  "Close": "Закрити",
  "Close editor": "Закрити редактор",
  "Close selection": "Закрити вибір",
  "Close the temporal Comparison": "Закрити тимчасове порівняння",
  "close_selected": "Закрити вибране",
  "closed": "закритий",
  "cloud": "Хмара",
  "Cloud Coverage": "Хмарність",
  "comment": "коментар",
  "comp. temporal target": "Тимчасова ціль",
  "comp. temporal variabilidad": "Тимчасова варіабельність",
  "comparación de conteo": "Порівняння підрахунку (%)",
  "comparación de estado hídrico": "Порівняння водного стану (%)",
  "comparación de estado hídrico drone": "Порівняння водного стану - Дрон (%)",
  "comparación de estado hídrico sat": "Порівняння водного стану - SAT (%)",
  "comparación de estimación de producción": "Порівняння оцінки виробництва (%)",
  "comparación de fósforo": "Порівняння фосфору (%)",
  "comparación de mapa de alertas": "Порівняння карти попереджень (%)",
  "comparación de mapa de faltas": "Порівняння карти відсутностей (%)",
  "comparación de media de conteo": "Порівняння середнього підрахунку (%)",
  "comparación de media de estado hídrico": "Порівняння середнього водного стану (%)",
  "comparación de media de fósforo": "Порівняння середнього фосфору (%)",
  "comparación de media de mapa de alertas": "Порівняння середньої карти попереджень (%)",
  "comparación de media de mapa de faltas": "Порівняння середньої карти відсутностей (%)",
  "comparación de media de nitrógeno": "Порівняння середнього азоту (%)",
  "comparación de media de potasio": "Порівняння середнього калію (%)",
  "comparación de media de producción total": "Порівняння середнього загального виробництва (%)",
  "comparación de media de rendimiento graso": "Порівняння середньої жирової продуктивності (%)",
  "comparación de medias de estimación de producción": "Порівняння середніх оцінок виробництва (%)",
  "comparación de medias de ndvi": "Порівняння середніх NDVI (%)",
  "comparación de medias de proteína": "Порівняння середніх білків (%)",
  "comparación de medias de variabilidad": "Порівняння середньої змінності (%)",
  "comparación de ndvi": "Порівняння NDVI (%)",
  "comparación de nitrógeno": "Порівняння азоту (%)",
  "comparación de nitrógeno relativo": "Порівняння відносного азоту (%)",
  "comparación de potasio": "Порівняння калію (%)",
  "comparación de producción total": "Порівняння загального виробництва (%)",
  "comparación de proteína": "Порівняння білків (%)",
  "comparación de rendimiento graso": "Порівняння жирової продуктивності (%)",
  "comparación de sacarosa": "Порівняння сахарози",
  "comparación de variabilidad": "Порівняння змінності (%)",
  "confirm": "Підтвердити",
  "Congratulations! Your field has been created.": "Вітаємо! Ваше поле створено.",
  "Congratulations! Your field has been edited.": "Вітаємо! Ваше поле було відредаговано.",
  "Contact": "Контакт",
  "Contenido de sacarosa ( % )": "Вміст сахарози ( % )",
  "conteo - pdf rgb": "підрахунок - pdf rgb",
  "conteo - shps": "підрахунок - shps",
  "conteo de individuos": "Підрахунок індивідів",
  "conteo lechugas": "Підрахунок салату",
  "continue": "продовжити",
  "CORN": "КУКУРУДЗА",
  "correction": "корекція",
  "Corte nitrogeno": "Зріз азоту",
  "cost": "вартість",
  "count (units)": "рахунок (одиниці)",
  "Counting": "Підрахунок",
  "create": "створити",
  "Create New Field": "Створити нове поле",
  "CREATE_RANGES": "На підставі гістограми зображення створіть діапазони з відсотком подібних даних у кожному діапазоні.",
  "created": "створено",
  "crecimiento cultivo": "Ріст культур",
  "Crop": "Культура",
  "Crop type": "Тип культури",
  "CROP_INSPECTION": "Інспекція культур",
  "cultive": "Культивування",
  "Cultivo": "Культура",
  "current season": "поточний сезон",
  "current selection could take a moment to process": "Обробка поточного вибору може зайняти деякий час",
  "Customer": "Клієнт",
  "customerProductionEstimation": "Оцінка виробництва клієнта",
  "customers": "Клієнти",
  "cut": "різати",
  "Cutter": "Різак",
  "dashboard": "панель управління",
  "date": "дата",
  "DD/MM/YYYY": "ДД/ММ/РРРР",
  "delete": "видалити",
  "Delete": "Видалити",
  "Delete downloaded report": "Видалити завантажений звіт",
  "delete downloaded sample": "видалити завантажений зразок",
  "Delete field": "Видалити поле",
  "delete layers?": "видалити шари?",
  "delete sample": "видалити зразок",
  "delete sample edition": "видалити редагування зразка",
  "DeleteField": "Поле було видалено",
  "Deliverables tutorial": "Інструкція по доставках",
  "Description": "Опис",
  "Deselect": "Скасувати вибір",
  "deselect all": "скасувати вибір всіх",
  "deviation": "відхилення",
  "Did you forget your password ?": "Ви забули свій пароль?",
  "disponible en visor": "Доступно в переглядачі",
  "do you want to delete": "ви хочете видалити",
  "documentation": "Документація",
  "don't show again": "не показувати знову",
  "Done": "Готово",
  "dosificación genérica": "Загальне дозування",
  "Dosis Media Cliente": "Середня доза клієнта",
  "Dosis media Propuesta": "Пропонована середня доза",
  "dosis riego": "Дозування зрошення",
  "Download": "Завантажити",
  "download failed": "Завантаження не вдалося",
  "Download list": "Список завантажень",
  "Download selected fields samples": "Завантажити вибрані зразки полів",
  "download selected geometries": "завантажити вибрані геометрії",
  "download values": "завантажити значення",
  "downloaded": "завантажено",
  "downloading layers": "завантаження шарів",
  "downloads": "завантаження",
  "Drag and drop your layers to arrange them in the list.": "Перетягніть свої шари, щоб впорядкувати їх у списку",
  "Drag Fields": "Перетягнути поля",
  "Drag your layers": "Перетягніть свої шари",
  "Draw Field": "Намалювати поле",
  "dron": "Дрон",
  "Dron sectores": "Сектори дрону",
  "drop here": "відпустити тут",
  "Dry": "Сухий",
  "dry matter": "Суха речовина",
  "dryMatterField": "Поле сухої речовини",
  "dryMatterLaboratory": "Лабораторія сухої речовини",
  "dsm": "Цифрова модель рельєфу",
  "earliest harvest date": "Найраніша дата збору врожаю",
  "edit": "редагувати",
  "Edit Field": "Редагувати поле",
  "Edit geometry": "Редагувати геометрію",
  "edit range": "редагувати діапазон",
  "Edit values": "Редагувати значення",
  "ej 100": "приклад 100",
  "End Date": "Дата завершення",
  "Equal Interval": "Рівний Інтервал",
  "Equidistant ranges": "Рівновіддалені діапазони",
  "error fetching classes": "Немає інформації про класи для поточного вибору. Спробуйте іншу комбінацію поля/дати.",
  "estado hídrico": "Водний стан",
  "estado hídrico (ndmi)": "Водний стан (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Оцінка",
  "estimación producción": "Оцінка виробництва",
  "Estimacion produccion ( Tn / Ha )": "Оцінка виробництва ( Тн / Га )",
  "estimación producción cualitativa": "Якісна оцінка виробництва",
  "estimación proteína": "Оцінка білка",
  "Estimacion proteina ( % )": " оцінка білка ( % )",
  "estimación sacarosa": "Оцінка сахарози",
  "estimación sacarosa ": "Оцінка сахарози",
  "estimación sacarosa cualitativa": "Якісна оцінка сахарози",
  "Estimacion Total ( Kg )": "загальна оцінка ( Кг )",
  "estimation gauging": "Оцінка калібрування",
  "estrategia de dosificacion": "Стратегія дозування",
  "estrés hídrico (lws)": "Водний стрес (LWS)",
  "evolución de NDVI": "Еволюція NDVI",
  "evolution": "еволюція",
  "Ex : comment": "приклад: коментар",
  "Ex : field R02": "приклад: поле R02",
  "Ex : Tresbolillos, row, tree": "приклад: Тресболіллос, ряд, дерево",
  "expected_final_weight": "очікувана кінцева вага",
  "external_id": "Зовнішній ідентифікатор",
  "extra parameters": "додаткові параметри",
  "falhas carreadores": "Недоліки носіїв",
  "falhas internas": "Внутрішні недоліки",
  "falhas terraços": "Недоліки терас",
  "fat performance": "Жирова продуктивність",
  "faults map": "Карта несправностей",
  "fdn": "Суха речовина",
  "Fecha de vuelo": "Дата польоту",
  "Fecha Plantacion": "Дата посадки",
  "Feddan": "Феддан",
  "fertilization strategy": "Стратегія удобрення",
  "Fertilizer recommendation": "Рекомендації щодо удобрення",
  "field": "поле",
  "Field Name": "Назва поля",
  "Field without geometry": "Поле без геометрії",
  "FIELD_FILTER": "Фільтри поля",
  "field_name": "Назва поля",
  "FIELD_TEMPORAL_EVOLUTION": "Тимчасова еволюція поля",
  "fields": "поля",
  "Fields without geometries": "Поля без геометрій",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Файл не може бути створений через велику кількість хмар. Будь ласка, виберіть іншу дату",
  "File generator": "Генератор файлів",
  "File name": "Назва файлу",
  "file type": "тип файлу",
  "file-is-not-available-in-selected-language": "Цей файл недоступний на вибраній мові. Створено англійською мовою.",
  "Fill plantation date of current field, showing one year of historic data": "Заповніть дату посадки поточного поля, показуючи один рік історичних даних",
  "Fill the field form": "Заповніть форму поля",
  "Fill the form to apply for all our services": "Заповніть форму, щоб подати заявку на всі наші послуги",
  "filtered field": "фільтроване поле",
  "filtered fields": "фільтровані поля",
  "Filters": "Фільтри",
  "final_weight": "Кінцева вага",
  "fitopatógenos": "Фітопатогени",
  "Flight Slider": "Повзунок польоту",
  "FLIGHT_FILTER": "Тимчасове порівняння",
  "flights type": "тип польотів",
  "fmsavi": "FMSAVI",
  "for everyone": "для всіх",
  "for me": "для мене",
  "forecasts": "прогнози",
  "formulario de abonado": "Форма удобрення",
  "formulario de dosis": "Форма дозування",
  "fósforo foliar": "Фоліарний фосфор",
  "fr": "Францій",
  "Fraction": "Дроб",
  "free": "безкоштовно",
  "from": "від",
  "fruits_100_weight": "Вага 100 фруктів",
  "fruits_actual1_weight": "Фактична вага фруктів",
  "Fuente de informacion": "джерело інформації",
  "gallery": "галерея",
  "gauging date": "Дата калібрування",
  "generation error": "помилка генерації",
  "geometry": "геометрія",
  "get a free trial": "отримати безкоштовну пробну версію",
  "Get back to editor": "Повернутися до редактора",
  "global files": "Завантажити попередню обробку, навчання та прогнозні PDF- та CSV-файли",
  "global layer": "Глобальний шар",
  "gm_cut_number": "Номер зрізу",
  "gm_data_generation": "Супутникова дата (тижні)",
  "gm_day_cycle": "Дні вирощування",
  "gm_harvest_estimated_date": "Орієнтовна дата збору врожаю (тижні)",
  "gm_hemav_atr_estimation": "Прогноз TRS",
  "gm_hemav_pol_estimation": "Прогноз поляризації",
  "gm_hemav_prodph_estimation": "Прогноз врожайності",
  "gm_hemav_realprodph_lastseason": "Фактична врожайність минулого сезону",
  "gm_hemav_sac_estimation": "Прогноз сахарози",
  "gm_luvi_date_generation": "Супутникова дата - LUVI (тижні)",
  "gm_luvi_day_cycle": "Дні вирощування (NDVI-LUVI)",
  "gm_luvi_mean": "Вегетативна живучість (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Змінність (NDVI-LUVI)",
  "gm_lws_mean": "Водний стрес (LWS)",
  "gm_ndmi_date_generation": "Супутникова дата - NDMI (тижні)",
  "gm_ndmi_day_cycle": "Дні вирощування (NDMI)",
  "gm_ndre_date_generation": "Супутникова дата - NDRE (тижні)",
  "gm_ndre_day_cycle": "Дні вирощування (NDRE)",
  "gm_season_label": "Сезон",
  "gm_sub_type_name": "Різновид",
  "gm_type_name": "Культура",
  "go to field": "Перейти до поля",
  "grid": "сітка",
  "harvest date": "Дата збору врожаю",
  "harvest planning": "Планування збору врожаю",
  "HARVEST_PLANNING_TOOL": "Інструмент планування збору врожаю",
  "HARVEST_PREDICTION": "Прогноз збору врожаю",
  "Hectare": "Гектар",
  "Hemav Default": "Типовий Hemav",
  "HEMAV Default": "HEMAV за замовчуванням",
  "Here, you can play with the preview of your different layers.": "Тут ви можете працювати з попереднім переглядом різних шарів",
  "hide": "приховати",
  "Hide All Layers": "Приховати всі шари",
  "hide filter list": "приховати список фільтрів",
  "Hide Global Layer": "Приховати глобальний шар",
  "Hide Measure Tools": "Приховати інструменти вимірювання",
  "high": "високий",
  "high level": "високий рівень",
  "high need": "висока потреба",
  "higher clay content": "вищий вміст глини",
  "humedad de suelo - beta (%)": "Вологість грунту - бета (%)",
  "humedad vegetativa (ndmi)": "Вегетативна вологість (NDMI)",
  "Hybrid View": "Гібридний вигляд",
  "hydric status": "Гідричний стан - NDMI",
  "hydric status drone": "Водний стан - Дрон",
  "If foliar sampler haven't been made, the date you must enter is the current": "Якщо фоліарний зразок не був зроблений, датою, яку ви повинні ввести, є поточна",
  "If you have two or more flights, you can compare them temporally using this tool.": "Якщо у вас є два або більше польотів, ви можете порівняти їх за часом за допомогою цього інструмента.",
  "If you have undone an action, you can press this button to redo it.": "Якщо ви скасували дію, ви можете натиснути цю кнопку, щоб повторити її.",
  "image": "зображення",
  "Impacto estimado": "Очікуваний вплив",
  "In this tab, you can download the generated PDFs.": "На цій вкладці ви можете завантажити створені PDF-файли.",
  "individuals_num": "Кількість індивідів",
  "infection": "інфекція",
  "Information": "Інформація",
  "informe": "звіт",
  "integración con maquinaria": "Інтеграція з машинами",
  "Invalid credentials": "Недійсні облікові дані",
  "invalid date": "недійсна дата",
  "Invalid email": "Недійсна електронна адреса",
  "invest": "інвестувати",
  "Irrigation recommendation": "Рекомендації з іригації",
  "Irrigation type": "Тип зрошення",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Зазвичай регулюється для встановлення кількості добрив або насіння, що будуть вноситися на одиницю площі.",
  "item_num": "номер елемента",
  "Keep selection": "Зберегти вибір",
  "label": "01.Мітка",
  "Label": "Мітка",
  "languaje": "мова",
  "last sample": "останній зразок",
  "lat": "широта",
  "layers": "шари",
  "layers estrés hídrico (lws)": "Шари водного стресу (LWS)",
  "layers status": "Стан шарів",
  "Legal limitation": "Юридичне обмеження",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"кільцева пляма\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"кільцева пляма\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"кільцева пляма\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"кільцева пляма\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"кільцева пляма\" - g5",
  "letter only": "тільки літери",
  "linear meters": "лінійні метри",
  "líneas de cultivo": "Лінії культур",
  "list": "список",
  "loading": "завантаження",
  "location": "розташування",
  "Log in": "Увійти",
  "logout": "Вийти",
  "lon": "довгота",
  "looks like its taking too long": "здається, це займає занадто багато часу",
  "low": "низький",
  "low level": "низький рівень",
  "low need": "низька потреба",
  "lower clay content": "нижчий вміст глини",
  "LUVI variability": "Змінність LUVI",
  "Machines Type": "Тип машин",
  "main_macronutrient": "Основний макроелемент",
  "malas hierbas": "Бур'яни",
  "malas hierbas - hoja estrecha": "Бур'яни - вузьколисті",
  "malas hierbas - hoja larga": "Бур'яни - широколисті",
  "malas hierbas - zonificado": "Бур'яни - зоновані",
  "malas hierbas shps": "Бур'яни SHPs",
  "malas hierbas shps - hoja estrecha": "Бур'яни SHPs - вузьколисті",
  "malas hierbas shps - hoja larga": "Бур'ни SHPs - широколисті",
  "malas hierbas shps - zonificado": "Бур'яни SHPs - зоновані",
  "Manage report": "Управління звітами",
  "max 2 years": "максимум 2 роки",
  "max comparable fields": "Максимум порівнянних полів",
  "maximo de variacion": "Максимальна варіація",
  "máximo vigor dos meses (ndvi)": "Максимальна живучість за два місяці (NDVI)",
  "máximo vigor un año (ndvi)": "Максимальна живучість за рік (NDVI)",
  "Maximun cloud coverage": "Максимальна хмарність",
  "mean": "середнє",
  "Measures tool tutorial": "Інструкція з інструментів вимірювання",
  "Measures tools": "Інструменти вимірювання",
  "media de faltas": "Середні несправності",
  "medias": "середні",
  "medio pot prod": "Середня потенційна продуктивність",
  "medium": "середній",
  "medium clay content": "середній вміст глини",
  "medium level": "середній рівень",
  "medium_weight_bigSize": "Середня вага великих фруктів",
  "medium_weight_mediumSize": "Середня вага середніх фруктів",
  "medium_weight_smallSize": "Середня вага малих фруктів",
  "Mensual limit": "Місячний ліміт",
  "Message": "Повідомлення",
  "METEOROLOGICAL_STATISTICS": "Метеорологічна статистика",
  "mode": "Режим",
  "model type": "тип моделі",
  "Move the slider": "Рухати повзунок",
  "msavi": "MSAVI",
  "N_BUNCHES": "Кількість пучків",
  "Name": "Назва",
  "nameless field": "Поле без назви",
  "Natural Breaks (Jenks)": "Природні Перерви (Дженкс)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "Реальний NDVI",
  "ndvi-curvas de nivel": "NDVI-криві рівня",
  "Network request failed": "Не вдалося виконати мережевий запит",
  "new sample": "новий зразок",
  "New sample created.": "Створено новий зразок.",
  "NITRO": "Азот",
  "nitrogen chlorophyll (ndre)": "Азот-хлорофіл (NDRE)",
  "nitrogen_assimilable": "Засвоюваний азот",
  "nitrógeno foliar": "Листовий азот",
  "Nivel de estres": "Рівень стресу",
  "no data": "Немає даних",
  "no data available": "Даних ще немає",
  "no data with current selection": "Немає даних з поточним вибором",
  "No fields selected": "Не вибрано жодного поля",
  "no fields to clear": "Немає полів для очищення",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Немає зображень на цей день.",
  "Not a user yet ?": "Ще не користувач?",
  "not enough data to display this chart": "Недостатньо даних для відображення цього графіка",
  "Not supported by browser": "Не підтримується браузером",
  "notes": "нотатки",
  "number only": "тільки числа",
  "Numero vuelo": "Кількість польотів",
  "observations": "спостереження",
  "offline access": "Oфлайн доступ",
  "offline mode": "Oфлайн режим",
  "oil_yield": "Врожайність олії",
  "ok": "ОК",
  "Once you are done, click this button to save the changes.": "Коли ви закінчите, натисніть цю кнопку, щоб зберегти зміни",
  "open": "відкрити",
  "Open": "Відкрити",
  "Open selection": "Відкрити вибір",
  "open temporal comparison": "відкрити тимчасове порівняння",
  "open_selected": "відкрити вибране",
  "optimize": "оптимізувати",
  "or": "або",
  "Order by a - z": "Сортувати за а - я",
  "Order by distance to the field": "Сортувати за відстанню до поля",
  "Order by z - a": "Сортувати за я - а",
  "ourDeviation": "Наше відхилення",
  "ourProductionEstimation": "Наша оцінка виробництва",
  "Parcela": "Ділянка",
  "Password": "Пароль",
  "pb": "Свинець",
  "pcd": "PCD",
  "pcd arbol": "PCD дерево",
  "pcd interpolado": "Інтерпольований PCD",
  "pcd row": "PCD ряд",
  "pdf": "PDF",
  "pending": "очікує",
  "phospohorus": "Фосфор",
  "phosporus": "Фосфор (ppm)",
  "photo": "фото",
  "photoid": "Фото ID",
  "photoId": "Фото",
  "picture_path": "Шлях до зображення",
  "plague": "Чума",
  "planned dosage": "Заплановане дозування",
  "Plant emitters": "Рослинні емітери",
  "plantation date": "Дата посадки",
  "Plantation date": "Дата посадки",
  "plantation_end_date_range": "Діапазон дат посадки",
  "plantation_start_date_range": "Діапазон дат посадки",
  "Plantations": "Плантації",
  "plants": "рослини",
  "Plants distance": "Відстань між рослинами",
  "plants_density_range": "Діапазон густоти рослин",
  "Please wait or order one to have data to visualized.": "Зачекайте або замовте, щоб мати дані для візуалізації.",
  "Please, choose a field variety": "Будь ласка, виберіть різновид поля",
  "Please, choose the field owner": "Будь ласка, виберіть власника поля",
  "plural selected": "вибрано декілька",
  "POL": "Поляризація",
  "Polygon drawing": "Малювання полігону",
  "positive number only": "тільки позитивне число",
  "positive number only and minor 100": "тільки позитивне число і менше 100",
  "pot. horticolar 90": "пот. садовий 90",
  "pot. otros cultivos 150": "пот. інші культури 150",
  "pot. otros cultivos 90": "пот. інші культури 90",
  "potasio foliar": "Листовий калій",
  "potencial productivo histórico (ndvi)": "Історичний продуктивний потенціал (NDVI)",
  "precio": "ціна",
  "PRED_TECH": "PRED_TECH",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Передбачені діапазони від HEMAV, які, як правило, підходять для більшості полів.",
  "prediction": "прогноз",
  "preprocessing": "попередня обробка",
  "Press this button to add a new field.": "Натисніть цю кнопку, щоб додати нове поле.",
  "Pressing this button lets you go back to the field view. ": "Натискання цієї кнопки дозволяє повернутися до перегляду поля.",
  "Preview": "Попередній перегляд",
  "price Kg": "ціна за кг",
  "primer vuelo": "Перший політ",
  "PROD": "Врожай на одиницю площі",
  "producción esperada": "Очікуване виробництво",
  "product Kg": "Продукт Кг",
  "product Kg is mandatory": "Продукт Кг обов'язковий",
  "Production estimate": "Оцінка виробництва",
  "production estimate (units)": "Оцінка виробництва (Кг/Га)",
  "profile": "Профіль",
  "protein": "Білок",
  "puccinia_sp_g1": "puccinia sp \"ржа\" - g1",
  "puccinia_sp_g2": "puccinia sp \"ржа\" - g2",
  "puccinia_sp_g3": "puccinia sp \"ржа\" - g3",
  "puccinia_sp_g4": "puccinia sp \"ржа\" - g4",
  "puccinia_sp_g5": "puccinia sp \"ржа\" - g5",
  "Quick start with Azure": "Швидкий старт з Azure",
  "Random fertilization": "Випадкове удобрення",
  "Random seedling": "Випадкове посів",
  "Range": "Діапазон",
  "Ranges adjusted to the index": "Діапазони, скориговані за індексом",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Діапазони однакового розміру будуть створені відповідно до мінімального та максимального значень зображення. У випадках, коли зображення дуже однорідне, ймовірно, не буде видно жодної відмінності.",
  "realProduction": "Реальне виробництво",
  "Recipients": "Одержувачі",
  "recomendación abonado dosificado": "Рекомендація з дозованого удобрення",
  "recomendación abonado zonificado": "Рекомендація з зонованого удобрення",
  "Recomendacion de riego": "Рекомендація зрошення",
  "recomendación riego": "Рекомендація зрошення",
  "Redo Action": "Повторити дію",
  "Related date": "Пов'язана дата",
  "relative nitrogen": "Відносний азот",
  "Rendimiento graso ( % )": "Жирова продуктивність ( % )",
  "report": "звіт",
  "Report sent correctly": "Звіт відправлено правильно",
  "report type": "Тип звіту",
  "Report type": "Тип звіту",
  "report_type_action_description": "Пропозиція заходів або дій, які слід застосувати",
  "report_type_annotation_description": "Діагностика: Аналіз на місці",
  "report_type_inspection_description": "Аналіз із ШАРІВ (закривається після аналізу)",
  "report_type_result_description": "Аналіз результатів застосування рішення або лікування.",
  "reporte agronómico": "Агрономічний звіт",
  "Reported by": "Повідомлено",
  "Reported fields": "Повідомлені поля",
  "reports manager": "Менеджер звітів",
  "required": "обов'язково",
  "Required": "Обов'язково",
  "Requires 3 characters minimun": "Мінімум 3 символи",
  "Reset": "Скинути",
  "resiembra": "Пересів",
  "resolution": "Роздільна здатність",
  "resolution (only SHP)": "Роздільна здатність (тільки для SHP)",
  "retención de agua": "Затримка води",
  "retry": "повторити",
  "rgb": "RGB",
  "richness": "Багатство",
  "riego zonificado": "Зоноване зрошення",
  "riesgo malherbología": "Ризик бур'янознавства",
  "Ruler": "Лінійка",
  "SAC": "Сахароза на одиницю площі",
  "saccharose estimate": "Оцінка сахарози",
  "sample": "зразок",
  "sample type": "тип зразка",
  "Sample was deleted.": "Зразок було видалено.",
  "Sample was successfully modified.": "Зразок було успішно змінено.",
  "sample_creation_date": "Дата створення зразка",
  "samples": "зразки",
  "satélite": "Супутник",
  "Satellite button tutorial": "Інструкція: Вид супутника",
  "Satellite View": "Вид з супутника",
  "save": "зберегти",
  "Save field": "Зберегти поле",
  "save_": "зберегти",
  "search": "пошук",
  "Search": "Пошук",
  "season": "сезон",
  "Season label": "Мітка сезону",
  "season name": "назва сезону",
  "SEASON_EVOLUTION": "Еволюція сезону",
  "Sector": "Сектор",
  "sectores target": "Цільові сектори",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Бачите? Тепер, натиснувши кнопку знову, ви можете повернутися до супутникового перегляду.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Другий політ",
  "Select": "Вибрати",
  "select a crop and sample": "Виберіть культуру та зразок",
  "select all": "вибрати все",
  "Select all": "Вибрати все",
  "select at least one field": "Виберіть принаймні одне поле",
  "select fields by": "вибрати поля за",
  "select fields by variability range": "вибрати поля за діапазоном варіабельності",
  "select fields to open temporal comparison": "Виберіть поля для відкриття тимчасового порівняння",
  "select one": "виберіть один",
  "select one customer please": "Виберіть одного клієнта, будь ласка",
  "select one subtype please": "Виберіть один підтип, будь ласка",
  "select one type please": "Виберіть один тип, будь ласка",
  "select sample base layer and date": "вибрати базовий шар зразка і дату",
  "select sample location": "вибрати місцезнаходження зразка",
  "select season": "вибрати сезон",
  "selected": "вибрано",
  "Selected fields names": "Назви вибраних полів",
  "Selected fields samples": "Вибрані зразки полів",
  "Selection includes too much information, try selecting a shorter date range": "Вибір включає занадто багато інформації, спробуйте вибрати коротший діапазон дат",
  "Send": "Надіслати",
  "Send report": "Надіслати звіт",
  "settings": "Налаштування",
  "Share fields report": "Поділитися звітом про поля",
  "show all": "показати все",
  "Show All Layers": "Показати всі шари",
  "Show current season only": "Показати тільки поточний сезон",
  "show fields with no data": "показати поля без даних",
  "show filter list": "показати список фільтрів",
  "Show Global Layer": "Показати глобальний шар",
  "Show Measure Tools": "Показати інструменти вимірювання",
  "shp-tooltip": "Ці числа можна змінювати в залежності від потреб використання і асоціюються з кожним класом геометрії у експортованій таблиці атрибутів SHP. Зазвичай вона налаштовується для встановлення кількості добрив або кількості насіння, що буде застосована на одиницю поверхні.",
  "singular selected": "вибрано один",
  "Slice tool only works on Polygons and Circle": "Інструмент зрізу працює тільки з полігонами та кругами",
  "Slider Tool": "Інструмент повзунка",
  "soil": "Ґрунт",
  "soil_type": "Тип грунту",
  "sólo para la generación de SHP": "Тільки для генерації SHP",
  "Sort": "Сортувати",
  "sort by date": "сортувати за датою",
  "Start date": "Дата початку",
  "static layers": "статичні шари",
  "Statistics": "Статистика",
  "status": "статус",
  "stem_num": "Кількість стебел",
  "Streets distance": "Відстань вулиць",
  "Subtype": "Підтип",
  "Suc/Ha": "Сахароза/Га",
  "sum_of_bases": "Сума баз",
  "Superficie ( Ha )": "Площа ( Га )",
  "Superficie Sector ( Ha )": "Площа сектора ( Га )",
  "surfaceUnit": "Площинна одиниця",
  "switch between all samples and current season samples": "переключатися між усіма зразками та зразками поточного сезону",
  "Switch to hybrid map": "Перейти на гібридну карту",
  "Switch to Mapbox": "Перейти на карту Mapbox",
  "Switch to satellite map": "Перейти на супутникову карту",
  "Switch to Terrain map": "Перейти на топографічну карту",
  "target date": "цільова дата",
  "target multiparcela": "Цільова багатопарцельна",
  "tell us": "розкажіть нам",
  "Temporal Comparison tutorial": "Інструкція з тимчасового порівняння",
  "textura del suelo": "Текстура грунту",
  "The date should be in DD/MM/YYYY format": "Дата повинна бути у форматі ДД/ММ/РРРР",
  "The distance must be in meters": "Відстань повинна бути в метрах",
  "the extra parameters will be removed": "додаткові параметри будуть видалені",
  "the following layers are no longer available in offline mode, download again if necessary": "наступні шари більше недоступні в офлайн режимі, завантажте їх знову, якщо це необхідно",
  "The production must be numeric/decimal": "Виробництво має бути числовим/десятковим",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "Стовпець праворуч показує загальну кількість для внесення відповідно до введеного значення за класами, і загальну кількість по ділянці внизу.",
  "The sample location must be inside one of the fields.": "Місцезнаходження зразка повинно бути всередині одного з полів.",
  "There are no samples to download": "Немає зразків для завантаження",
  "There is no operated flight on this field.": "На цьому полі не було здійснено жодного польоту",
  "Thermal Accumulation": "Термічне накопичення",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Ці числа можуть бути змінені відповідно до потреб застосування і асоціюються з кожною геометрією в таблиці атрибутів експортованого SHP.",
  "This action will delete the field and all its related data": "Ця дія видалить поле та всі пов'язані з ним дані",
  "this action will modify your field selection": "ця дія змінить ваш вибір поля",
  "this action will open field view and lose current field selection": "ця дія відкриє перегляд поля і втратить поточний вибір поля",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Ця кнопка дозволяє малювати лінії або полігони для вимірювання відстані або площі.",
  "This button allows you to switch to the hybrid view.": "Ця кнопка дозволяє перейти на гібридний вид",
  "This can take a long time": "Це може зайняти багато часу",
  "This field is not downloaded.": "Це поле не завантажено",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Цей інструмент дозволяє різати полігони. Використовуйте одиночні клацання, щоб намалювати лінію, і подвійний клік, щоб закінчити малювання.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Цей інструмент дозволяє малювати полігони. Спробуйте намалювати простий. Переконайтеся, що ви закрили його!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Цей інструмент дозволяє вимірювати відстань між набором точок. Використовуйте одиночні клацання, щоб встановити точки, і подвійний клік, щоб закінчити малювання.",
  "Those fields are mandatory": "Ці поля є обов'язковими",
  "TIME_COMPARISON_DROP_ZONE": "Зони викиду...",
  "TINTA BARROCA": "ТІНТА БАРРОКА",
  "TINTA FRANCISCA": "ТІНТА ФРАНЦІСКА",
  "TINTO": "ЧЕРВОНИЙ",
  "TINTO CÃO": "ЧЕРВОНИЙ КАО",
  "tipo de dosificacion": "Тип дозування",
  "to": "до",
  "today": "сьогодні",
  "todos": "всі",
  "tons of cane - annual vision": "Тонни цукрової тростини - щорічний погляд",
  "Total": "Загальна кількість",
  "total production": "Загальне виробництво (Кг)",
  "total_fruits_bigSize": "Загальна кількість великих фруктів",
  "total_fruits_mediumSize": "Загальна кількість середніх фруктів",
  "total_fruits_smallSize": "Загальна кількість малих фруктів",
  "total_weight": "Загальна вага",
  "total_weight_bigSize": "Загальна вага великих фруктів",
  "total_weight_canes": "Загальна вага тростин",
  "total_weight_mediumSize": "Загальна вага середніх фруктів",
  "total_weight_smallSize": "Загальна вага малих фруктів",
  "TOURIGA FRANCA": "ТУРІГА ФРАНКА",
  "training": "тренування",
  "TRINCADEIRA": "ТРІНКАДЕЙРА",
  "Try our mobile app for a better experience": "Спробуйте наш мобільний додаток для кращого досвіду",
  "type": "Культура",
  "Type": "Тип культури",
  "type here your notes": "введіть тут свої нотатки",
  "Type your text": "Введіть ваш текст",
  "type_sampler": "Тип зразка",
  "Undo Action": "Скасувати дію",
  "unidades": "Одиниці",
  "unitSystem": "Система одиниць",
  "Unknown": "Невідомий",
  "unnamed field": "Поле без назви",
  "unsaved changes will be lost": "незбережені зміни будуть втрачені",
  "update sample": "оновити зразок",
  "updated": "оновлено",
  "Use this buttons to select which layer to compare.": "Використовуйте ці кнопки, щоб вибрати, який шар порівнювати.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Використовуйте цей інструмент, щоб тимчасово порівняти два польоти. Щоб почати, перетягніть один із польотів до того, з яким ви хочете його порівняти.",
  "Use this tool to freely move the created polygons.": "Використовуйте цей інструмент, щоб вільно переміщати створені полігони.",
  "used": "Використано",
  "Username": "Ім'я користувача",
  "users": "користувачі",
  "Value": "Значення",
  "variabilidad multiparcela": "Багатопарцельна варіабельність",
  "variabilidad parcela (vra)": "Змінна площа парцели (VRA)",
  "variability": "варіабельність",
  "Variedad": "Різновид",
  "vegetative growth": "Вегетативний ріст",
  "vegetative growth per day": "Вегетативний ріст на день",
  "vegetative growth variability": "Варіабельність вегетативного зростання",
  "Ver detalles": "Переглянути деталі",
  "very high": "дуже високий",
  "very high level": "дуже високий рівень",
  "very low": "дуже низький",
  "very low level": "дуже низький рівень",
  "view": "перегляд",
  "vigor": "ЖИВУЧІСТЬ",
  "vigor a nivel árbol": "живучість / рослина",
  "vigor acumulado dos meses (ndvi)": "Накопичена живучість два місяці (NDVI)",
  "vigor acumulado un año (ndvi)": "Накопичена живучість один рік (NDVI)",
  "vigor vegetativo": "Вегетативна живучість",
  "vigor vegetativo - pcd": "PCD - Вегетативна живучість",
  "vigor vegetativo (ndvi)": "Вегетативна живучість (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Вегетативна живучість (NDVI) - Ранні стадії",
  "vigor zonificado": "Зонована живучість",
  "VINHÃO": "ВІНЬЯО",
  "Visibility": "Видимість",
  "Vulnerable area": "Уразлива зона",
  "wait": "чекати",
  "warning": "попередження",
  "warning map": "карта попереджень",
  "Water level": "Рівень води",
  "We are processing the data for your flight. Results will be available soon.": "Ми обробляємо дані вашого польоту. Результати будуть доступні незабаром.",
  "weeds area": "Зона бур'янів",
  "week": "тиждень",
  "weight_1": "Вага 1",
  "weight_2": "Вага 2",
  "weight_3": "Вага 3",
  "weight_4": "Вага 4",
  "weight_5": "Вага 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "За допомогою цього інструмента ви можете малювати круги. Натисніть, щоб встановити центр, і натисніть ще раз, щоб вибрати радіус.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "За допомогою цього інструмента ви можете намалювати полігон з 3 або більше точок для вимірювання його площі",
  "without warning": "Без попередження",
  "Wrong credentials": "Невірні облікові дані",
  "year": "рік",
  "years": "Рік",
  "You can change here the information of the new field.": "Ви можете змінити інформацію про нове поле тут",
  "You can move this bar to compare the layers temorally.": "Ви можете переміщати цю панель, щоб порівняти шари за часом.",
  "You can only compare dates from the same sources": "Ви можете порівнювати лише дати з одних і тих же джерел",
  "You can use this button at any moment to undo your last actions.": "Ви можете в будь-який момент використовувати цю кнопку, щоб скасувати свої останні дії.",
  "You can use this slider to change the opacity of the selected layer.": "Ви можете використовувати цей повзунок, щоб змінити непрозорість вибраного шару.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Ви не можете створити поле, тому що у вас немає клієнтів. Будь ласка, попросіть нас додати деяких.",
  "You must enter a decimal": "Ви повинні ввести десяткове число",
  "You must enter an integer": "Ви повинні ввести ціле число",
  "You must select an irrigation type": "Ви повинні вибрати тип зрошення",
  "You must type a field name": "Ви повинні ввести назву поля",
  "You must type a text": "Ви повинні ввести текст",
  "Your field has been successfully deleted.": "Ваше поле було успішно видалено",
  "Your LAYERS version has expired. Click to update.": "Термін дії вашої версії LAYERS закінчився. Натисніть, щоб оновити."
}
