import { loader } from 'graphql.macro'
import moment from 'moment'

import GeometryUtils from '../geometryUtils'
import { getConfigData, getConfigProjection } from '../selectors/config'
import { getSelectedFieldsIds } from '../selectors/fields'
import { errorNotification } from './notifications'
import { fetchRequestWrapper, fetchGraphqlWrapper } from './ui'

import { getSeasonById, getSeasonsByIds } from '../selectors/seasons'
import { getSnapshots } from '../selectors/snapshots'
import { getSeasonIdsWithinDateRangeByFieldIds, getSeasonIdsByFieldIdAndDate } from '../selectors/fieldsSeasonsTimeline'

import {
  DELIVERABLE_TYPE_PREFIXES,
  RADAR_ASC_SNAPSHOT_SOURCE,
  RADAR_DESC_SNAPSHOT_SOURCE,
  RADAR_SNAPSHOT_SOURCE,
  SAT_SNAPSHOT_SOURCE,
  PLANET_SNAPSHOT_SOURCE,
  DRONE_RGB_SNAPSHOT_SOURCE,
  DRONE_SNAPSHOT_SOURCE,
  LANDSAT_SNAPSHOT_SOURCE
} from '@layers-frontend/commons/constants'

import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import unionBy from 'lodash/unionBy'
import toString from 'lodash/toString'
import union from 'lodash/union'

import { getSeasonTileOrdersSubscriptionByDateRange, hasSelectedFieldTileOrderSubscription } from '../selectors/fieldsTileOrder'
import { hasRolePlanet } from '@layers-frontend/commons/store/selectors/user'

export const REQUEST_SATELLITE_FLIGHT_DATES = 'REQUEST_SATELLITE_FLIGHT_DATES'
export const RECEIVE_SATELLITE_FLIGHT_DATES = 'RECEIVE_SATELLITE_FLIGHT_DATES'
export const REQUEST_SNAPSHOTS_BY_SOURCE = 'REQUEST_SNAPSHOTS_BY_SOURCE'
export const RECEIVE_SNAPSHOTS_BY_SOURCE = 'RECEIVE_SNAPSHOTS_BY_SOURCE'
export const REQUEST_DB_SNAPSHOTS = 'REQUEST_DB_SNAPSHOTS'
export const RECEIVE_DB_SNAPSHOTS = 'RECEIVE_DB_SNAPSHOTS'

// eslint-disable-next-line dot-notation
const RADAR_DELIVERABLE_TYPE_PREFIXES = DELIVERABLE_TYPE_PREFIXES['RADAR']

const buildFlightsFromSHFeatures = features => {
  return map(features, feature => {
    return {
      date: get(feature, ['properties', 'date'])
    }
  })
}

const receiveSatelliteFlights = (seasonId, snapshotSource, cloudCoverage) => flightsCollection => {
  let flights = get(flightsCollection, 'features')
  if (snapshotSource === RADAR_ASC_SNAPSHOT_SOURCE || snapshotSource === RADAR_DESC_SNAPSHOT_SOURCE) {
    const orbit = snapshotSource === RADAR_ASC_SNAPSHOT_SOURCE ? 'ASCENDING' : 'DESCENDING'
    flights = filter(
      flights,
      flight => includes(get(flight, ['properties', 'id']), RADAR_DELIVERABLE_TYPE_PREFIXES[0]) && get(flight, ['properties', 'orbitDirection']) === orbit
    )
  }

  return {
    type: RECEIVE_SATELLITE_FLIGHT_DATES,
    receivedAt: Date.now(),
    flights: buildFlightsFromSHFeatures(flights),
    seasonId,
    source: snapshotSource,
    cloudCoverage
  }
}

const fetchExternalFlights = ({ seasonId, bbox, cloudCoverage = 100.0, from, to, features = 200, snapshotSource }) => (dispatch, getState) => {
  const state = getState()
  const sentinelConfig = getConfigData(state, 'sentinel')
  const projectionConfig = getConfigProjection(state)

  // todo: this could be done only when source is planet and has planet role
  const planetSourceInfo = getSeasonTileOrdersSubscriptionByDateRange(seasonId, from, to)(state)
  const isPlanetSource = snapshotSource === PLANET_SNAPSHOT_SOURCE
  const hasActiveTileOrder = hasRolePlanet(state) && isPlanetSource && planetSourceInfo

  const sourceConfig = {
    [SAT_SNAPSHOT_SOURCE]: {
      typeNames: 'DSS2',
      apiKey: get(sentinelConfig, 'apiKey')
    },
    [LANDSAT_SNAPSHOT_SOURCE]: {
      typeNames: 'DSS12',
      apiKey: get(sentinelConfig, 'apiKey')
    },
    [RADAR_SNAPSHOT_SOURCE]: {
      typeNames: 'DSS3',
      apiKey: get(sentinelConfig, 'apiKey')
    },
    [PLANET_SNAPSHOT_SOURCE]: {
      typeNames: `byoc-${planetSourceInfo?.collectionId}`,
      apiKey: planetSourceInfo?.instanceId
    }
  }

  const getConfigFromSnapshotSource = source => {
    switch (source) {
      case SAT_SNAPSHOT_SOURCE:
        return sourceConfig[SAT_SNAPSHOT_SOURCE]
      case RADAR_ASC_SNAPSHOT_SOURCE:
      case RADAR_DESC_SNAPSHOT_SOURCE:
        return sourceConfig[RADAR_SNAPSHOT_SOURCE]
      case LANDSAT_SNAPSHOT_SOURCE:
        return sourceConfig[LANDSAT_SNAPSHOT_SOURCE]
      case PLANET_SNAPSHOT_SOURCE:
        return sourceConfig[PLANET_SNAPSHOT_SOURCE]
      default:
        return sourceConfig[SAT_SNAPSHOT_SOURCE]
    }
  }
  const config = getConfigFromSnapshotSource(snapshotSource)
  const baseUrl = get(sentinelConfig, 'baseUrl')
  // eslint-disable-next-line dot-notation
  const typeNames = config['typeNames']
  // eslint-disable-next-line dot-notation
  const apiKey = config['apiKey']
  const srsName = get(projectionConfig, 'to')
  const outputFormat = 'application/json'
  const wfsUrl = new URL(`${baseUrl}/wfs/${apiKey}?service=WFS&version=2.0.0&request=GetFeature`)
  wfsUrl.searchParams.set('time', `${from}/${to}`)
  wfsUrl.searchParams.set('typenames', typeNames)
  wfsUrl.searchParams.set('maxfeatures', features)
  wfsUrl.searchParams.set('srsname', srsName)
  wfsUrl.searchParams.set('bbox', bbox)
  wfsUrl.searchParams.set('outputformat', outputFormat)
  wfsUrl.searchParams.set('maxcc', cloudCoverage)

  const fetchOptions = {
    requestType: REQUEST_SATELLITE_FLIGHT_DATES,
    customRoute: wfsUrl.toString(),
    onSuccess: receiveSatelliteFlights(seasonId, snapshotSource, cloudCoverage),
    onError: () => dispatch(errorNotification('An error occured fetching dates on sentinel. Please try again')),
    overlay: false
  }

  if (isPlanetSource && !hasActiveTileOrder) return

  return dispatch(fetchRequestWrapper(fetchOptions))
}

const fetchSatelliteFlights = flightsRequestData => dispatch => {
  return Promise.all(
    map(flightsRequestData, (requestData, seasonId) => {
      const { bbox, from, to } = requestData
      return dispatch(fetchExternalFlights({ seasonId, bbox, from, to, snapshotSource: SAT_SNAPSHOT_SOURCE }))
    })
  )
}

const fetchLandsatFlights = flightsRequestData => dispatch => {
  return Promise.all(
    map(flightsRequestData, (requestData, seasonId) => {
      const { bbox, from, to } = requestData
      return dispatch(fetchExternalFlights({ seasonId, bbox, from, to, snapshotSource: LANDSAT_SNAPSHOT_SOURCE }))
    })
  )
}

const fetchRadarFlights = flightsRequestData => (dispatch, getState) => {
  const state = getState()
  return Promise.all(
    map(flightsRequestData, (requestData, seasonId) => {
      const { bbox, from, to } = requestData
      // check if customer have asc or desc radar deliverable
      const season = getSeasonById(seasonId)(state)
      const deliverables = get(season, 'deliverables')
      const radarDeliverables = unionBy(
        get(deliverables, [RADAR_ASC_SNAPSHOT_SOURCE, 'deliverables']),
        get(deliverables, [RADAR_DESC_SNAPSHOT_SOURCE, 'deliverables']),
        'type.id'
      )
      const radarDeliverable = !isEmpty(radarDeliverables) && get(radarDeliverables, '0')
      if (radarDeliverable) {
        const snapshotSource = includes(radarDeliverable.type.base_file_name, 'ASC') ? RADAR_ASC_SNAPSHOT_SOURCE : RADAR_DESC_SNAPSHOT_SOURCE
        return dispatch(fetchExternalFlights({ seasonId, bbox, from, to, snapshotSource }))
      }
    })
  )
}

const fetchPlanetFlights = flightsRequestData => dispatch => {
  return Promise.all(
    map(flightsRequestData, (requestData, seasonId) => {
      const { bbox, from, to } = requestData
      const monthMiddle = moment(from).add(14, 'day').format('YYYY-MM-DD')

      dispatch(fetchExternalFlights({ seasonId, bbox, from, to: monthMiddle, snapshotSource: PLANET_SNAPSHOT_SOURCE }))
      return dispatch(fetchExternalFlights({ seasonId, bbox, from: monthMiddle, to, snapshotSource: PLANET_SNAPSHOT_SOURCE }))
    })
  )
}

export const fetchSelectedFieldsFlights = (from, to) => (dispatch, getState) => {
  const state = getState()
  const selectedFieldsIds = getSelectedFieldsIds(state)
  const seasonsIds = getSeasonIdsWithinDateRangeByFieldIds(state, selectedFieldsIds, from, to)
  const seasons = getSeasonsByIds(seasonsIds)(state)

  const flightsRequestData = reduce(
    seasons,
    (accumulator, season) => {
      const seasonId = get(season, 'id')
      const geometry = get(season, 'geometry')
      if (isNil(geometry)) {
        return accumulator
      }

      // calc from and to based on season
      const seasonStartDate = moment(get(season, 'startDate'))
      const seasonEndDate = moment(get(season, 'endDate'))
      const requestFrom = seasonStartDate.isSameOrBefore(from, 'day') ? from : seasonStartDate
      const requestTo = seasonEndDate.isSameOrAfter(to, 'day') ? to : seasonEndDate
      // calc season bbox
      const geometryInMeters = GeometryUtils.translateGeometry(geometry)
      const bbox = GeometryUtils.geometryToBbox(geometryInMeters)
      return {
        ...accumulator,
        [seasonId]: {
          bbox: join(bbox, ','),
          from: requestFrom.format('YYYY-MM-DD'),
          to: requestTo.format('YYYY-MM-DD')
        }
      }
    },
    {}
  )
  const snapshotsPromises = [
    dispatch(fetchSatelliteFlights(flightsRequestData)),
    dispatch(fetchRadarFlights(flightsRequestData)),
    dispatch(fetchLandsatFlights(flightsRequestData)),
    dispatch(fetchSelectedFieldsDroneFlights(selectedFieldsIds))
  ]

  const hasActiveSubscription = hasSelectedFieldTileOrderSubscription(state)

  if (hasActiveSubscription) {
    snapshotsPromises.push(dispatch(fetchPlanetFlights(flightsRequestData)))
  }

  return Promise.all(snapshotsPromises)
}

// DRONE FLIGHTS
const buildFlightsFromDBSnapshots = snapshots => {
  return map(snapshots, snapshot => {
    return {
      cloudCoverage: get(snapshot, 'cloudCoverage'),
      seasonId: get(snapshot, 'seasonId'),
      source: get(snapshot, 'source'),
      date: moment(get(snapshot, ['date'])).format('YYYY-MM-DD'),
      deliverables: get(snapshot, 'deliverables[0]')
    }
  })
}

const receiveSnapshotsBySources = snapshots => {
  // build flights from db snapshots

  return {
    type: RECEIVE_SNAPSHOTS_BY_SOURCE,
    snapshots: buildFlightsFromDBSnapshots(snapshots)
  }
}

const receiveSnapshots = rawSnapshots => (dispatch, getState) => {
  const state = getState()
  const fetchedSnapshots = getSnapshots(state)
  const { snapshots, droneSnapshots } = rawSnapshots
  const parsedSnapshots = reduce(
    [...snapshots, ...droneSnapshots],
    (accumulator, current) => {
      const fieldId = get(current, 'field_id')
      const date = moment(get(current, 'date')).format('YYYY-MM-DD')
      const seasonId = getSeasonIdsByFieldIdAndDate(state, fieldId, date)
      const cloudCoverage = get(current, 'cloudCoverage')
      const source = get(current, 'source')
      const previousDateData = get(accumulator, date, {})
      const previousDateSourceData = get(previousDateData, source, {})
      const deliverables = get(current, 'deliverables[0]')
      const previousDeliverables = get(previousDateSourceData, 'deliverables')

      const previousSeasons = get(previousDateSourceData, 'seasons', [])
      const season = { id: toString(seasonId), cloudCoverage }

      const isRadarSnapshot = includes([RADAR_ASC_SNAPSHOT_SOURCE, RADAR_DESC_SNAPSHOT_SOURCE], source)
      const isDroneSnapshot = includes([DRONE_SNAPSHOT_SOURCE, DRONE_RGB_SNAPSHOT_SOURCE], source)

      // filter out drone snapshots with no deliverables
      if (!deliverables && isDroneSnapshot) return accumulator

      if (isRadarSnapshot) {
        // check if customer have asc or desc radar deliverable
        const seasonWithDeliverables = getSeasonById(seasonId)(state)
        const deliverables = get(seasonWithDeliverables, 'deliverables')
        const radarDeliverables = unionBy(
          get(deliverables, [RADAR_ASC_SNAPSHOT_SOURCE, 'deliverables']),
          get(deliverables, [RADAR_DESC_SNAPSHOT_SOURCE, 'deliverables']),
          'type.id'
        )
        const radarDeliverable = !isEmpty(radarDeliverables) && get(radarDeliverables, '0')
        // eslint-disable-next-line no-undef-init
        let radarDeliverableSnapshotSource = undefined
        if (radarDeliverable) {
          radarDeliverableSnapshotSource = includes(radarDeliverable.type.base_file_name, 'ASC') ? RADAR_ASC_SNAPSHOT_SOURCE : RADAR_DESC_SNAPSHOT_SOURCE
        }
        if (radarDeliverableSnapshotSource !== source) {
          return accumulator
        }
      }
      return {
        ...accumulator,
        [date]: {
          ...previousDateData,
          [source]: {
            ...previousDateSourceData,
            date,
            source,
            seasons: union(previousSeasons, [season]),
            deliverables: {
              ...previousDeliverables,
              [seasonId]: deliverables
            }
          }
        }
      }
    },
    fetchedSnapshots
  )
  return dispatch({
    type: RECEIVE_DB_SNAPSHOTS,
    snapshots: parsedSnapshots
  })
}

const getSnapshotsBySourceQuery = loader('../graphql/snapshots/getSnapshotsBySource.gql').loc.source.body
const fetchSnapshotsBySources = (fieldIds, sources) => {
  return fetchGraphqlWrapper({
    query: getSnapshotsBySourceQuery,
    variables: { fieldIds, sources },
    requestType: REQUEST_SNAPSHOTS_BY_SOURCE,
    overlay: false,
    onSuccess: ({ snapshots }) => receiveSnapshotsBySources(snapshots),
    onError: console.log
  })
}
export const fetchSelectedFieldsDroneFlights = fieldIds => dispatch => {
  const sources = [DRONE_RGB_SNAPSHOT_SOURCE, DRONE_SNAPSHOT_SOURCE]
  return dispatch(fetchSnapshotsBySources(fieldIds, sources))
}

const getSnapshotsQuery = loader('../graphql/snapshots/getSnapshots.gql').loc.source.body

export const fetchDBFlights = (fieldIds, from, to) => (dispatch, getState) => {
  const state = getState()
  const satSources = [SAT_SNAPSHOT_SOURCE, RADAR_SNAPSHOT_SOURCE, RADAR_ASC_SNAPSHOT_SOURCE, RADAR_DESC_SNAPSHOT_SOURCE, LANDSAT_SNAPSHOT_SOURCE]
  const hasActiveSubscription = hasSelectedFieldTileOrderSubscription(state)

  if (hasActiveSubscription) satSources.push(PLANET_SNAPSHOT_SOURCE)

  return dispatch(
    fetchGraphqlWrapper({
      query: getSnapshotsQuery,
      variables: {
        fieldIds,
        from,
        to,
        satSources,
        droneSources: [DRONE_SNAPSHOT_SOURCE, DRONE_RGB_SNAPSHOT_SOURCE]
      },
      requestType: REQUEST_DB_SNAPSHOTS,
      overlay: false,
      onSuccess: receiveSnapshots,
      onError: console.log
    })
  )
}
