import React, { useState } from 'react'
import { Tab, Tabs, Icon } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const Wrapper = styled.div`
  .tabs {
    & > div {
      background-color: transparent !important;
    }
  }

  .custom-icon {
    color: ${themeColors.whiteColor} !important;
    font-size: 2.2rem !important;
    width: 50px;
    &.active {
      color: ${themeColors.vomitColor} !important;
    }
  }
`

const TabContentWrapper = styled.div`
  padding: 1rem;
`

const muiStyles = {
  inkBarStyle: {
    backgroundColor: themeColors.vomitColor,
    height: 3
  },
  buttonStyle: {
    height: '5rem'
  }
}

export default function FileGeneratorTabs({ tabs = [] }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const handleChange = (_, index) => setActiveTabIndex(index)

  return (
    <Wrapper>
      <Tabs variant="fullWidth" onChange={handleChange} value={activeTabIndex} className="tabs" textColor="inherit">
        {tabs.map(({ icon }, tabIndex) => (
          <Tab
            key={tabIndex}
            value={tabIndex}
            sx={muiStyles.buttonStyle}
            icon={<Icon className={`${icon} custom-icon ${activeTabIndex === tabIndex && 'active'}`} />}
            data-testid={`file-generator-tab-${tabIndex}`}
          />
        ))}
      </Tabs>
      <SwipeableViews index={activeTabIndex} onChangeIndex={handleChange}>
        {tabs.map(({ content }, tabIndex) => (
          <TabContentWrapper key={tabIndex}>{content}</TabContentWrapper>
        ))}
      </SwipeableViews>
    </Wrapper>
  )
}
