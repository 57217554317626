import React, { useEffect } from 'react'
import { injectGlobal } from 'styled-components'
import Accounts from '../containers/AccountsContainer/Accounts'
import withTranslator from './HOCComponents/withTranslator'
import { useGetUserConfigByUserIdQuery, useGetPoliciesQuery } from '../features/api/accountsConfigApi'
import { updateDefaultAccount, updateFavourites } from '../features/accounts/accountsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { accountListPolicies, findMatchingPolicies, getPolicyValue } from '../features/api/utils'
import { ROUTES } from '../constants'
import { Navigate } from 'react-router-dom'

function AccountPage({ t }) {
  const dispatch = useDispatch()

  useEffect(() => {
    injectGlobal`
    body {background:#15384f}
    }
  `
  }, [])

  const tokenData = useSelector(state => state.userLayers.token)
  const token = tokenData?.data?.access_token
  const userId = useSelector(state => state.userLayers.user?.data?.id)
  const organizationId = useSelector(state => state.userLayers.organizations.data[0]?.id)

  const { data: userConfig, error, isLoading } = useGetUserConfigByUserIdQuery({ organizationId, userId, token })
  const { data: policiesData, error: policiesError, isLoading: policiesLoading } = useGetPoliciesQuery({ organizationId, token })

  useEffect(() => {
    if (userConfig && userId) {
      const matchingPolicies = findMatchingPolicies(accountListPolicies, userConfig, userId)

      const favouriteAccounts = getPolicyValue(matchingPolicies, 'favourite_accounts')
      const defaultAccount = getPolicyValue(matchingPolicies, 'default_login_account')

      defaultAccount && dispatch(updateDefaultAccount({ key: 'defaultAccount', payload: defaultAccount }))
      favouriteAccounts && dispatch(updateFavourites({ key: 'favouriteAccounts', payload: favouriteAccounts }))
    }
  }, [userConfig, userId, dispatch])

  if (!organizationId || !userId) {
    const redirectRoute = `/${ROUTES.LOGIN_ROUTE}`
    return <Navigate to={redirectRoute} replace />
  }

  if (isLoading || policiesLoading) return <div>Loading...</div>
  if (error || policiesError) return <div>Error: {error}</div>

  return <div>{userConfig && policiesData && <Accounts t={t} />}</div>
}

export default withTranslator(AccountPage)
