import { SET_ACTIVE_CALENDAR_DATE, SET_CALENDAR_DOTS, SET_CLOUD_COVERAGE, SET_TEMPORAL_COMPARISON_DATE } from '../actions/calendar'
import { ADD_FLIGHT_GROUPS, SELECT_SNAPSHOT } from '../actions/fields'
import { OPEN_SEARCH_MODE } from '../actions/viewmode'
import moment from 'moment'
import get from 'lodash/fp/get'
import reduce from 'lodash/fp/reduce'
import set from 'lodash/set'
import { CALENDAR_LEGENDS } from '@layers-frontend/commons/constants'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { MAX_CLOUD_COVERAGE } from '../constants'

const convertReduce = reduce.convert({ cap: false })

const initialState = {
  activeDate: moment(),
  tempComparisonDate: undefined,
  calendarDots: {},
  cloudCoverage: MAX_CLOUD_COVERAGE
}

export const calendar = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CALENDAR_DATE:
      return {
        ...state,
        activeDate: action.date
      }
    case SET_CALENDAR_DOTS:
      return {
        ...state,
        calendarDots: action.dots
      }
    case SET_CLOUD_COVERAGE:
      return {
        ...state,
        cloudCoverage: action.cloudCoverage
      }
    case ADD_FLIGHT_GROUPS:
      /* eslint-disable no-case-declarations */
      const flightGroups = action.payload
      const activeYearMonth = state.activeDate.format('YYYY-MM')
      const calendarDots = convertReduce((accumulator, flightGroup, index) => {
        /* eslint-enable no-case-declarations */
        const flightInfo = get('flights[0]')(flightGroup)
        const date = get('date')(flightInfo)
        const momentDate = moment(date)
        const yearMonth = momentDate.format('YYYY-MM')
        const formattedDate = momentDate.format('YYYY-MM-DD')
        const source = get('source')(flightInfo)
        const cloudCoverage = get('seasons[0].cloudCoverage')(flightInfo)
        const previousMonthYearData = get(yearMonth)(accumulator)
        const previousDateData = get(formattedDate)(previousMonthYearData) || []

        // we fetch drone flights by season (others by date range), but we should fetch calendarDots by chosen month
        // such way we don't save extra calendar dots (drone flights dots) for not selected months
        if (activeYearMonth !== yearMonth && isUndefined(state.calendarDots?.[yearMonth])) return accumulator

        const coloredDot = {
          // eslint-disable-next-line object-shorthand
          source: source,
          color: get('color')(CALENDAR_LEGENDS[source]),
          index,
          cloudCoverage
        }

        return {
          ...accumulator,
          [yearMonth]: {
            ...previousMonthYearData,
            [formattedDate]: [...previousDateData, coloredDot]
          }
        }
      }, {})(flightGroups)

      // fix infinite spinner for months without flightGroups (because we need to have key with fetched year/month)
      if (isEmpty(calendarDots[activeYearMonth])) set(calendarDots, activeYearMonth, {})

      return {
        ...state,
        // eslint-disable-next-line object-shorthand
        calendarDots: calendarDots
      }
    case SET_TEMPORAL_COMPARISON_DATE:
      return {
        ...state,
        tempComparisonDate: action.date
      }
    case SELECT_SNAPSHOT:
    case OPEN_SEARCH_MODE:
      return initialState

    default:
      return state
  }
}
