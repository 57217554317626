import React, { useEffect, useState } from 'react'
import { withTranslator } from '../HOCComponents/withTranslator'
import '../../utils'
import { ROLE_AGRO, ROLE_COOPERATIVE } from '../../actions/users'
import { SAMPLES_MODE } from '../../constants'
import { withAuthentication } from '../HOCComponents/withAuthentication'
import { UIStyles } from '../../theme'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import map from 'lodash/map'
import assign from 'lodash/assign'
import some from 'lodash/some'
import has from 'lodash/fp/has'
import truncate from 'lodash/truncate'
import DownloadCSVContainer from '../../containers/UtilitiesContainers/DownloadCSVContainer'
import RenderIf from '@layers-frontend/commons/helpers/RenderIf'
import { Switch, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { convertAsString } from '@layers-frontend/commons/conversions'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

const truncateLength = 30

const FieldInfoWrapper = styled.div`
  display: ${props => (props.inline ? 'flex' : 'block')};
  align-items: center;
  i {
    margin-right: 10px;
  }
`

export const FieldInfo = withTranslator(({ name, subtitle, icon, t, inline }) => (
  <FieldInfoWrapper inline={inline}>
    <div className="desc-icon">
      <i className={icon} />
    </div>
    {name ? String(name.toLowerCase()) : t('Unknown')}
    {subtitle && <span style={{ color: UIStyles.grayColor }}> {String(subtitle.toLowerCase())} </span>}
  </FieldInfoWrapper>
))

export const ShowCustomerName = withAuthentication([ROLE_AGRO, ROLE_COOPERATIVE])(FieldInfo)

const IconWrapper = styled.div`
  width: 26px;
  color: ${props => props.color || UIStyles.whiteColor};
  font-weight: 200;
  font-size: 22px;
  z-index: 3;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: center;
  transform: ${props => props.rotated && 'rotate(180deg)'};
`

const DownloadCSVNodeWrapper = styled.div`
  color: ${UIStyles.vomitColor};
  cursor: pointer;
  margin-right: 15px;
`

const StyledIcon = styled.i`
  font-size: ${props => props.size || '1.6rem'};
  cursor: ${props => (props.onClick || props.pointer ? 'pointer' : 'initial')};
`

const Title = styled.h2`
  font-size: 15px;
  letter-spacing: 1px;
  color: ${UIStyles.vomitColor};
  font-weight: 300;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  flex: 1;
`

const SurfaceInfoWrapper = styled.div`
  display: flex;
  color: ${UIStyles.whiteColor};
  align-items: center;
  span {
    margin-left: 10px;
  }
`

const selectedFieldsMapFunctionAndTranslate = t => {
  let hasExternalReference
  const fieldsCSVHeader = t('fields')
  const usersCSVHeader = t('users')
  const unNamedField = t('unnamed field')

  return (currentField, index, fields) => {
    if (!currentField) return null
    if (index === 0) {
      hasExternalReference = some(fields, has('externalReferenceId'))
    }

    const { name, customer, externalReferenceId } = currentField
    const fieldName = name || unNamedField
    const customerName = get(customer, 'name')

    return assign(
      {
        [fieldsCSVHeader]: fieldName,
        [usersCSVHeader]: customerName
      },
      hasExternalReference && { externalReferenceId }
    )
  }
}

const BottomError = styled.div`
  padding: 5px 10px;
`

const Wrapper = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: 2.5rem 1fr 2.5rem;
  grid-gap: 1rem;
  align-items: baseline;
`

const Text = styled.p`
  color: ${UIStyles.whiteColor};
  grid-column: 2 / 4;
  margin-bottom: 0;
`

const ToggleWrapper = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: -10px;
`

const RightIconWrapper = styled(IconWrapper)`
  grid-column: 3 / 4;
`

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-left: 20px;
`
const toggleButtonSx = {
  textTransform: 'none',
  color: themeColors.whiteColor,
  padding: '0.2em 1em',
  border: `1px solid ${themeColors.vomitColor}`,
  borderRadius: '10px',
  fontSize: '1.2rem',
  '&.Mui-selected': { background: themeColors.vomitColorTransparent },
  '&.Mui-selected:hover': { background: themeColors.vomitLightHover },
  ':hover': { background: themeColors.vomitLightHover }
}

const switchSx = {
  marginLeft: '10px'
}

function FieldDescription({
  fields,
  temporal,
  flightGroups,
  openSearchMode,
  closeEditMode,
  toggleShowSampleMarkers,
  shouldShowSamplesInMap,
  asideExpanded,
  toggleExpandSelectedFieldAside,
  t,
  samplesMode,
  setSamplesMode,
  surfaceUnit,
  hasSamples,
  selectedFlightGroupDate
}) {
  const title = `${fields.length} ${fields.length === 1 ? t('field') : t('fields')}`
  const isSeasonSampleMode = samplesMode === SAMPLES_MODE.SEASON_SAMPLES

  const surface = fields
    .reduce((sum, field) => {
      const surface = get(field, 'season.surface', 0)
      return surface ? sum + parseFloat(surface) : sum
    }, 0)
    .toFixed(2)
  const onCloseClick = temporal ? closeEditMode : openSearchMode
  const selectedFields = fields

  const [hasImagesInSelectedDay, setHasImagesInSelectedDay] = useState(true)

  useEffect(() => {
    const flightsInSelectedDate = flightGroups.filter(group => group.flights[0].date === selectedFlightGroupDate)
    const hasDeliverablesInSelectedDay = flightsInSelectedDate[0]?.deliverables.length !== 0
    setHasImagesInSelectedDay(hasDeliverablesInSelectedDay)
  }, [flightGroups, selectedFlightGroupDate])

  const hasFlights = flightGroups.length
  const hasDeliverables = flightGroups.reduce((sum, flightGroup) => sum + (flightGroup.deliverables.length ? 1 : 0), 0)
  const inProcessing = !!(hasFlights && !hasDeliverables)
  const fieldDescriptionTitleTruncate = truncate(title, { length: truncateLength })

  const handleChangeSamplesMode = (event, newMode) => {
    const mode = !isSeasonSampleMode ? SAMPLES_MODE.SEASON_SAMPLES : SAMPLES_MODE.ALL_SAMPLES
    if (newMode === mode) {
      setSamplesMode(newMode)
    }
  }

  return (
    <>
      <Wrapper id="div-description-general-field" data-testid="field-description">
        <DownloadCSVContainer
          data={map(selectedFields, selectedFieldsMapFunctionAndTranslate(t))}
          styledWrapper={DownloadCSVNodeWrapper}
          node={
            <StyledTooltip title={t('Download list')} placement="bottom-start">
              <StyledIcon className={'fas fa-download'} pointer />
            </StyledTooltip>
          }
        />
        <Title data-testid="selection-info">{fieldDescriptionTitleTruncate}</Title>
        <RightIconWrapper color={UIStyles.redColor} justifyContent="center" data-testid="close-field-description-button">
          <StyledTooltip title={capitalize(t('Close'))} placement="bottom">
            <StyledIcon size="2rem" className={'fas fa-close'} onClick={onCloseClick} />
          </StyledTooltip>
        </RightIconWrapper>
        <RenderIf condition={true}>
          <SurfaceInfoWrapper>
            <StyledIcon className="fas fa-ruler-combined" />
          </SurfaceInfoWrapper>
          <Text data-testid="text-surface">{convertAsString(surface, 'hectare', surfaceUnit)}</Text>
        </RenderIf>
        <RenderIf condition={hasSamples}>
          <ToggleWrapper>
            <IconWrapper>
              <StyledIcon className={'fas fa-tag'} />
            </IconWrapper>
            <StyledTooltip title={`${capitalize(t(shouldShowSamplesInMap ? 'hide' : 'view'))} ${t('samples')}`} placement="bottom">
              <Switch sx={switchSx} checked={shouldShowSamplesInMap} onChange={e => toggleShowSampleMarkers(e.target.checked)} />
            </StyledTooltip>
            <StyledTooltip title={`${capitalize(t('switch between all samples and current season samples'))}`} placement="bottom">
              <StyledToggleButtonGroup
                value={isSeasonSampleMode ? SAMPLES_MODE.SEASON_SAMPLES : SAMPLES_MODE.ALL_SAMPLES}
                exclusive
                onChange={handleChangeSamplesMode}
              >
                <ToggleButton value={SAMPLES_MODE.ALL_SAMPLES} aria-label={t('all')} sx={toggleButtonSx}>
                  {capitalize(t('all'))}
                </ToggleButton>
                <ToggleButton value={SAMPLES_MODE.SEASON_SAMPLES} aria-label={t('season')} sx={toggleButtonSx}>
                  {capitalize(t('season'))}
                </ToggleButton>
              </StyledToggleButtonGroup>
            </StyledTooltip>
          </ToggleWrapper>
        </RenderIf>
        <RightIconWrapper color={UIStyles.vomitColor} hidden={false} rotated={!asideExpanded} justifyContent="flex-end">
          <StyledTooltip title={capitalize(t(asideExpanded ? 'ocultar' : 'Ver detalles'))} placement="bottom">
            <StyledIcon size="2rem" className="icon-arrow" onClick={() => toggleExpandSelectedFieldAside(!asideExpanded)} />
          </StyledTooltip>
        </RightIconWrapper>
      </Wrapper>

      <RenderIf condition={!hasImagesInSelectedDay}>
        <BottomError>
          <Text>{t('NO_DELIVERABLES_IMAGES_MESSAGE')}</Text>
        </BottomError>
      </RenderIf>
      <RenderIf condition={!hasFlights || !hasDeliverables}>
        <BottomError>
          <Text>
            {!hasFlights && t('There is no operated flight on this field.')}
            {inProcessing && t('We are processing the data for your flight. Results will be available soon.')}
          </Text>
        </BottomError>
      </RenderIf>
    </>
  )
}

export default withTranslator(FieldDescription)
