import { connect } from 'react-redux'
import SampleForm from '../../../components/FieldsComponents/SampleForm/SampleForm'
// eslint-disable-next-line import/no-duplicates
import { getSampleTypes } from '@layers-frontend/commons/store/selectors/sampleTypes'
import { closeSampleLocationMode, openSampleLocationMode } from '../../../actions/viewmode'
// eslint-disable-next-line import/no-duplicates
import { getSamplesParametersByType } from '@layers-frontend/commons/store/selectors/sampleTypes'
// eslint-disable-next-line import/no-duplicates
import { getSamplesFormExtraParameters, getSamplesFormExtraParametersOrder } from '@layers-frontend/commons/store/selectors/samplesForm'
import { setFormField, removeSampleFormExtraParam, setSampleFormExtraParamValueById } from '@layers-frontend/commons/store/actions/samplesForm'
import {
  getSamplesFormBaseLayerId,
  getSamplesFormLocation,
  getSamplesFormNotes,
  getSamplesFormSampleCreationDate,
  getSamplesFormType,
  getSamplesFormPictureTempPath
  // eslint-disable-next-line import/no-duplicates
} from '@layers-frontend/commons/store/selectors/samplesForm'
import {
  formattedOptionsOfSampleExtraParameters,
  formattedOptionsOfSelectedSampleExtraParameters,
  getSampleFormExtraParameterById
} from '../../../selectors/sampleForm'
import { getSelectedFlightGroupDeliverables, getSelectedFlightGroupFirstVisibleDeliverable } from '../../../selectors/flightGroups'
import {
  setSampleFormExtraParameters,
  setSampleFormType,
  postPatchSample,
  resetAndCloseSampleForm,
  resetSampleFormPicturePath
} from '../../../actions/samplesForm'
import { getS3Url } from '../../../actions/utilities'
import { getSamplesFormImagePath, isNewSampleMode } from '../../../selectors/samplesForm'
import { getDeliverablesTypesNameById } from '../../../selectors/deliverables'

const mapStateToProps = state => ({
  type: getSamplesFormType(state),
  location: getSamplesFormLocation(state),
  extraParameters: getSamplesFormExtraParameters(state),
  pictureTempPath: getSamplesFormPictureTempPath(state),
  sampleTypes: getSampleTypes(state),
  selectedFlightGroupFirstVisibleDeliverable: getSelectedFlightGroupFirstVisibleDeliverable(state),
  selectedFlightGroupDeliverables: getSelectedFlightGroupDeliverables(state),
  extraParametersOrder: getSamplesFormExtraParametersOrder(state),
  parametersByType: getSamplesParametersByType(state),
  sampleExtraParameters: formattedOptionsOfSampleExtraParameters(state),
  getSampleFormExtraParameterById: getSampleFormExtraParameterById(state),
  picturePath: getSamplesFormImagePath(state),
  selectedSampleExtraParameters: formattedOptionsOfSelectedSampleExtraParameters(state),
  sampleCreationDate: getSamplesFormSampleCreationDate(state),
  notes: getSamplesFormNotes(state),
  baseLayer: getSamplesFormBaseLayerId(state),
  getDeliverablesTypesNameById: getDeliverablesTypesNameById(state),
  isNewSampleMode: isNewSampleMode(state)
})

const mapDispatchToProps = {
  setFormField,
  setSampleFormExtraParameters,
  setSampleFormExtraParamValueById,
  removeSampleFormExtraParam,
  openSampleLocationMode,
  setSampleFormType,
  closeSampleLocationMode,
  resetAndCloseSampleForm,
  postPatchSample,
  resetSampleFormPicturePath,
  getS3Url
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleForm)
