import PropTypes from 'prop-types'

import OLComponent from './ol-component'
import OlCluster from 'ol/source/Cluster'
import OlVectorSource from 'ol/source/Vector'
import OlVectorLayer from 'ol/layer/Vector'

import OlPoint from 'ol/geom/Point'
import OlFeature from 'ol/Feature'

import OlMap from 'ol/Map'

import { pie, arc } from 'd3-shape'
import { bin, sum, range } from 'd3-array'
import get from 'lodash/get'
import map from 'lodash/map'

import { select, selectAll } from 'd3-selection'
import { NO_VALUE, DAY_CYCLE_CAP, CLUSTER_ZOOM_THRESHOLD } from '@layers-frontend/commons/constants'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { roundBasedOnRange } from '../../utils'
import isEqual from 'lodash/isEqual'

export default class Cluster extends OLComponent {
  constructor(props) {
    super(props)
    this.clusterSource = null
    const filteredFeatures = props.features.filter(f => f && f.centroid)
    const feats = []
    for (let i = 0; i < filteredFeatures.length; ++i) {
      const point = new OlPoint(filteredFeatures[i].centroid.coordinates)
      point.transform('EPSG:4326', 'EPSG:3857')
      feats[i] = new OlFeature({
        geometry: point,
        ...filteredFeatures[i].properties
      })
    }

    const vectorSource = new OlVectorSource({
      features: feats
    })

    const clusterSource = new OlCluster({
      source: vectorSource,
      distance: 200
    })

    this.layer = new OlVectorLayer({
      source: clusterSource,
      name: 'cluster_layer',
      renderMode: 'image',
      visible: true
    })
  }

  getChildContext() {
    return {
      layer: this.layer,
      map: this.context.map
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedLayerLegend.name !== prevProps.selectedLayerLegend.name ||
      this.props.selectedLayerLegend.legend !== prevProps.selectedLayerLegend.legend ||
      !isEqual(this.props.filteredByEverything, prevProps.filteredByEverything)
    ) {
      this.drawClusters()
    }
  }

  filterFeature = (feature, touchedLegend) => {
    const name = get(touchedLegend, 'baseName')
    const isScaleFromDB = get(touchedLegend, 'isScaleFromDB')
    const isScaleTypeCategoric = get(touchedLegend, 'isScaleTypeCategoric')
    const sequentialRange = get(touchedLegend, 'legend.value') // sequential
    const showNulls = get(touchedLegend, 'legend.showNulls') // sequential
    const legend = get(touchedLegend, 'legend')
    const touchedLegendValue = feature[name]

    if (!isScaleFromDB && isScaleTypeCategoric) {
      const isInIndex = legend.findIndex(l => l.value === touchedLegendValue)
      return isInIndex !== -1 && !legend[isInIndex].visible
    }
    if (!isScaleFromDB && !isScaleTypeCategoric) {
      if (showNulls && touchedLegendValue === null) return false

      const maxSequentialValue =
        touchedLegendValue && name === 'gm_luvi_day_cycle' ? (touchedLegendValue > DAY_CYCLE_CAP ? DAY_CYCLE_CAP : touchedLegendValue) : touchedLegendValue

      return !(maxSequentialValue && sequentialRange && maxSequentialValue >= sequentialRange[0] && maxSequentialValue <= sequentialRange[1])
    }
    const colorIndex = legend.findIndex(legend => {
      const range = legend.range
      if (!touchedLegendValue && range === NO_VALUE) return true
      return touchedLegendValue && range && touchedLegendValue >= range[0] && touchedLegendValue <= range[1]
    })
    return colorIndex !== -1 && legend[colorIndex] && !legend[colorIndex].visible
  }

  drawClusters = () => {
    const newZoom = this.context.map.getView().getZoom()
    if (newZoom >= CLUSTER_ZOOM_THRESHOLD) return
    const clusterLayer = this.layer
    if (clusterLayer) {
      const { touchedLayers } = this.props
      const selectedLayer = this.props.selectedLayerLegend
      const legend = selectedLayer.legend
      const isScaleFromDB = selectedLayer.isScaleFromDB
      const isScaleTypeCategoric = selectedLayer.isScaleTypeCategoric
      const isSequential = !isScaleFromDB && !isScaleTypeCategoric
      const clusterSource = clusterLayer.getSource()
      const clusters = clusterSource.getFeatures()
      selectAll('.clusters').remove()
      const filteredFeatures = []
      legend &&
        clusters.forEach(cluster => {
          const coordinates = cluster.getGeometry().getCoordinates()
          const position = this.context.map.getPixelFromCoordinate(coordinates)
          const clusterFeatures = cluster.get('features')
          const pieChartData = clusterFeatures.reduce((data, feature) => {
            const properties = feature.getProperties()
            const isFeatureHiddenValues = map(touchedLayers, touchedLegend => this.filterFeature(properties, touchedLegend))
            const isFeatureHidden = isFeatureHiddenValues.filter(f => f !== false)
            if (isFeatureHidden.length) {
              return data
            }
            if (this.props.isFilterable && !this.props.filteredByEverything.find(f => f.id === properties.gm_field_id)) {
              return data
            }
            filteredFeatures.push(properties.gm_field_id)
            if (isScaleFromDB) {
              const featureValue = feature.get(selectedLayer.baseName)
              const index = legend.findIndex(l => {
                const range = l.range
                if (!featureValue && range === NO_VALUE) return true
                if (featureValue && range && featureValue >= range[0] && featureValue <= range[1]) {
                  return true
                }
                return false
              })
              if (index === -1) {
                return data
              }
              const color = legend[index].color
              const value = legend[index].name
              // return object with value and count, if value exists, increment count
              const existingData = data.findIndex(d => d.value === value)
              if (existingData !== -1) {
                data[existingData].count++
              } else {
                data.push({ value, count: 1, color })
              }
            }
            if (isScaleTypeCategoric) {
              const featureValue = feature.get(selectedLayer.baseName)
              const scale = selectedLayer.scale
              const index = legend.findIndex(l => l.value === featureValue)
              if (index === -1) {
                return data
              }
              const value = legend[index].value
              const maxSelectedValue = value && selectedLayer.baseName === 'gm_luvi_day_cycle' ? (value > DAY_CYCLE_CAP ? DAY_CYCLE_CAP : value) : value
              const color = scale && maxSelectedValue ? scale(maxSelectedValue) : themeColors.lightGreySolid
              // return object with value and count, if value exists, increment count
              const existingData = data.findIndex(d => d.value === value)
              if (existingData !== -1) {
                data[existingData].count++
              } else {
                data.push({ value: maxSelectedValue, count: 1, color })
              }
            }
            if (isSequential) {
              const featureValue = feature.get(selectedLayer.baseName)

              const scale = selectedLayer.scale
              if (legend.showNulls && !featureValue) {
                data.null = data.null ? data.null + 1 : 1
              }
              const maxSelectedValue =
                featureValue && selectedLayer.baseName === 'gm_luvi_day_cycle' ? (featureValue > DAY_CYCLE_CAP ? DAY_CYCLE_CAP : featureValue) : featureValue

              const existingData = data.findIndex(d => d.value === maxSelectedValue)
              if (existingData !== -1) {
                data[existingData].count++
              } else {
                data.push({ value: maxSelectedValue, count: 1, color: scale(maxSelectedValue) })
              }
            }
            return data
          }, [])

          const { min: scaleMin, max: scaleMax } = legend
          const binnedSequentialData = bin()
            .value(d => d.value)
            .domain([scaleMin, scaleMax])
            .thresholds(range(scaleMin, scaleMax + 1, (scaleMax - scaleMin) / 10))(pieChartData)

          const binnedSequentialDataWithNull = [...binnedSequentialData, [{ value: null, count: pieChartData.null, color: themeColors.lightGreySolid }]]

          const pieChartTotals = pieChartData.reduce((acc, curr) => {
            return acc + curr.count
          }, 0)

          const pieChartWidth = 100 // Width of the pie chart
          const pieChartHeight = 100 // Height of the pie chart
          const svg = select('.ol-viewport')
            .append('svg')
            .attr('class', 'clusters')
            .attr('width', pieChartWidth)
            .attr('height', pieChartHeight)
            .style('left', position[0] - pieChartWidth / 2 + 'px')
            .style('top', position[1] - pieChartWidth / 2 + 'px')
            .style('position', 'absolute')

          // Define the pie chart layout
          const pieFn = pie().value(d => d.count)(pieChartData)
          const pieFnForBins = pie()
            .value(bin => sum(bin, d => d.count))
            .sortValues((aBin, bBin) => {
              return aBin.x0 - bBin.x0
            })(binnedSequentialDataWithNull)

          const tooltipForCategorical = d => {
            return `${d.data.value === null || d.data.value === 'No hay datos' ? this.props.t('no data') : d.data.value}: ${d.data.count}`
          }
          const tooltipForSequential = sector => {
            const isBinOfFieldsWithoutData = sector.data[0]?.value === null
            const textAmountFieldsWithoutData = `${this.props.t('no data')}: ${sector.value}`

            const roundedX0 = roundBasedOnRange(scaleMin, scaleMax, sector.data.x0)
            const roundedX1 = roundBasedOnRange(scaleMin, scaleMax, sector.data.x1)
            const range = roundedX0 === roundedX1 ? roundedX0 : `${roundedX0}-${roundedX1}`
            const textAmountFieldsWithinRange = `${range}: ${sector.value}`

            return isBinOfFieldsWithoutData ? textAmountFieldsWithoutData : textAmountFieldsWithinRange
          }

          // Create arcs for the pie slices
          const arcFn = arc()
            .innerRadius(30)
            .outerRadius(Math.min(pieChartWidth, pieChartHeight) / 2 - 10)

          svg
            .append('circle')
            .attr('cx', pieChartWidth / 2)
            .attr('cy', pieChartHeight / 2)
            .attr('r', 25)
            .attr('fill', themeColors.blueColor)
            .attr('stroke', themeColors.vomitColor)
            .attr('stroke-width', pieChartTotals === 0 ? 2 : 0)

          // Create pie chart slices as <path> elements
          svg
            .selectAll('.path')
            .data(isSequential ? pieFnForBins : pieFn)
            .enter()
            .append('path')
            .attr('transform', `translate(${pieChartWidth / 2}, ${pieChartHeight / 2})`)
            .attr('d', arcFn)
            .attr('fill', d => (isSequential ? d.data[0]?.color : d.data.color))
            .on('mouseover', function () {
              select(this).attr('stroke', 'white').attr('stroke-width', 2)
            })
            .on('mouseout', function () {
              select(this).attr('stroke', 'none')
            })
            .append('title')
            .text(isSequential ? tooltipForSequential : tooltipForCategorical)

          if (clusterFeatures.length === pieChartTotals) {
            svg
              .append('text')
              .attr('x', pieChartWidth / 2)
              .attr('y', pieChartHeight / 2)
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'central')
              .attr('fill', themeColors.vomitColor)
              .attr('font-size', '12px')
              .text(pieChartTotals)
          } else {
            svg
              .append('text')
              .attr('x', pieChartWidth / 2)
              .attr('y', pieChartHeight / 2 - 5)
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'text-top')
              .attr('fill', themeColors.vomitColor)
              .attr('font-size', '12px')
              .text(`${pieChartTotals}`)

            // append a line with the size of the text element
            svg
              .append('line')
              .attr('x1', pieChartWidth / 2 - 14)
              .attr('y1', pieChartHeight / 2)
              .attr('x2', pieChartWidth / 2 + 14)
              .attr('y2', pieChartHeight / 2)
              .attr('stroke', themeColors.vomitColor)
              .attr('stroke-width', 1)

            svg
              .append('text')
              .attr('x', pieChartWidth / 2)
              .attr('y', pieChartHeight / 2 + 5)
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'hanging')
              .attr('fill', themeColors.vomitColor)
              .attr('font-size', '12px')
              .text(`${clusterFeatures.length}`)
          }
        })
      if (!isEqual(this.props.filteredFeatures, filteredFeatures)) {
        this.props.setFilteredFieldIds(filteredFeatures)
      }
    }
  }

  componentDidMount() {
    this.context.map.addLayer(this.layer)

    this.context.map.on('movestart', () => {
      selectAll('.clusters').remove()
    })
    setTimeout(this.drawClusters, 0)
    this.context.map.on('moveend', this.drawClusters)
  }

  componentWillUnmount() {
    selectAll('.clusters').remove()
    this.context.map.removeLayer(this.layer)
    this.context.map.un('moveend', this.drawClusters)
  }
}
Cluster.propTypes = {}

Cluster.defaultProps = {}

Cluster.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

Cluster.childContextTypes = {
  layer: PropTypes.instanceOf(OlVectorLayer),
  map: PropTypes.instanceOf(OlMap),
  test: PropTypes.string
}
/*
LayerVector
  SourceCluster
    SourceVector
      Feature
        MultiPolygon
*/
