import { ROUTES } from './constants'
import includes from 'lodash/includes'

const { LAYERS_ROUTE, DASHBOARD_ROUTE, SAMPLE_IMAGE_ROUTE, BILABS_ROUTE, ACCOUNTS_ROUTE } = ROUTES

/* eslint-disable camelcase */
export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - this.length
    /* eslint-enable camelcase */
    while (k-- + 1) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'isNullObject', {
  value: function () {
    return Object.keys(this).length === 0 && this.constructor === Object
  },
  writable: true,
  configurable: true,
  enumerable: false
})

export default class Utils {
  static hexToRgbA(hex, opacity) {
    /* eslint-disable no-var */
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = '0x' + c.join('')
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
    }
    throw new Error('Bad Hex')
  }

  static hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    /* eslint-enable no-var */
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  static formatDate(date) {
    date = new Date(date)
    return (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear()
  }

  static clone(obj) {
    // eslint-disable-next-line yoda
    if (null == obj || 'object' != typeof obj) return obj
    // eslint-disable-next-line no-var
    var copy = obj.constructor()
    // eslint-disable-next-line no-var
    for (var attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr]
    }
    return copy
  }

  // static formatFieldForm(form) {
  //   let formData = {}

  //   for (var key in form) {
  //     // extra steps when the last polygon in removed z
  //     if (key == 'coords' && form[key].value.length == 0) {
  //       form[key].value = ''
  //     }
  //     /** if it's came from autocomplete **/
  //     if (typeof form[key].value === 'object' && form[key].value !== null) {
  //       /** if already exist we put the id **/
  //       if (form[key].value.value !== null) {
  //         formData[key] = form[key].value.value
  //       } else {
  //         formData[key] = form[key].value.name
  //       }
  //     } else if (key === 'plantation_year') {
  //       formData[key] = moment(form[key].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
  //     } else {
  //       formData[key] = form[key].value
  //     }
  //   }

  //   return JSON.stringify(formData)
  // }

  static executeFunctionByName(functionName, context /*, args */) {
    /* eslint-disable no-var */
    var args = Array.prototype.slice.call(arguments, 2)
    var namespaces = functionName.split('.')
    var func = namespaces.pop()
    for (var i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]]
    }
    /* eslint-enable no-var */
    return context[func].apply(context, args)
  }

  /*=============================================<<<<<*/
}

// eslint-disable-next-line no-useless-escape
export const replaceNotNumbersChars = string => (typeof string === 'string' ? string.replace(/[^0-9\.]+/gi, '') : undefined)

export const isDashboardRoute = location => includes(location.pathname, DASHBOARD_ROUTE)

export const isViewerRoute = location => includes(location.pathname, LAYERS_ROUTE)

export const isSampleImageRoute = location => includes(location.pathname, SAMPLE_IMAGE_ROUTE)

export const isBiLabsRoute = location => includes(location.pathname, BILABS_ROUTE)

export const isAccountsRoute = location => includes(location.pathname, ACCOUNTS_ROUTE)

/**
 * Rounds a given value based on the specified range min and max.
 * If both the range min and max are integers and their difference is greater than 1,
 * the value is rounded to the nearest integer. Otherwise, the value is rounded to one decimal place.
 *
 * @param {number} rangeMin - The minimum value of the range.
 * @param {number} rangeMax - The maximum value of the range.
 * @param {number} value - The value to be rounded.
 * @returns {(string|undefined)} The rounded (or unchanged) value as a
 * string, or `undefined` if any of arguments weren't provided.
 */
export const roundBasedOnRange = (rangeMin, rangeMax, value) => {
  // if value is not provided -- immediate return. Note: value could === 0 and it's ok.
  if (!value && value !== 0) {
    return
  }

  // If range is not provided -- returns value as is, but in the string format.
  // Note: at least rangeMin (and possibly rangeMax) could be 0, and it's ok.
  if ((!rangeMin || !rangeMax) && rangeMin !== 0 && rangeMax !== 0) {
    return value.toString()
  }

  const minIsInteger = rangeMin % 1 === 0
  const maxIsInteger = rangeMax % 1 === 0

  // If the range boundaries are integers, and their difference is grater than 1, round tha value to integer.
  if (minIsInteger && maxIsInteger && rangeMax - rangeMin > 1) {
    return value.toFixed(0)
  } else {
    return value.toFixed(1)
  }
}
