import { useSelector } from 'react-redux'
import { useGetAccountsByUserIdQuery } from '../../features/api/accountsApi'
import {
  useGetUserConfigByUserIdQuery,
  useGetPoliciesQuery,
  useGetAccountPoliciesQuery,
  useGetAccountConfigurationQuery
} from '../../features/api/accountsConfigApi'

/**
  Custom hook to fetch accounts data, configuration data, and accounts configuration data for a user.
**/

const useAccountsData = () => {
  const tokenData = useSelector(state => state.userLayers.token)
  const token = tokenData?.data?.access_token
  const userId = useSelector(state => state.userLayers.user.data.id)
  const organizations = useSelector(state => state.userLayers.organizations.data)
  const organizationId = organizations[0]?.id

  const { data: accountsData, error: accountsError, isLoading: isAccountsLoading } = useGetAccountsByUserIdQuery({ token, organizationId })

  const { data: configData, error: configError, isLoading: isConfigLoading } = useGetUserConfigByUserIdQuery({ userId, token, organizationId })

  const { data: userPoliciesData, error: userPoliciesError, isLoading: userPoliciesLoading } = useGetPoliciesQuery({ organizationId, token })

  const { data: accountPoliciesData, error: accountPoliciesError, isLoading: accountPoliciesLoading } = useGetAccountPoliciesQuery({ organizationId, token })

  const accountsIds = accountsData?.map(acc => acc.id)

  const { data: accountConfigData, error: accountConfigError, isLoading: accountConfigLoading } = useGetAccountConfigurationQuery(
    {
      accountsIds,
      organizationId,
      token
    },
    {
      skip: !accountsData && !accountsIds
    }
  )

  return {
    userId,
    accountsData,
    accountsError,
    isAccountsLoading,
    configData,
    configError,
    isConfigLoading,
    userPoliciesData,
    userPoliciesError,
    userPoliciesLoading,
    accountPoliciesData,
    accountPoliciesError,
    accountPoliciesLoading,
    accountConfigData,
    accountConfigError,
    accountConfigLoading
  }
}

export default useAccountsData
