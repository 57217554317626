import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'

import FileGeneratorButton from '../../components/ToolsComponents/FileGeneratorButton'
import { getSelectedFieldsLength } from '../../selectors/fields'
import { isFieldView } from '../../selectors/viewmode'
import { isSelectedFlightGroupSatType, isSelectedFlightGroupRadarType } from '../../selectors/flightGroups'
import { setSelectedFieldTab } from '../../actions/selectedFieldTabs'

const mapStateToProps = state => ({
  selectedFieldsLength: getSelectedFieldsLength(state),
  isFieldView: isFieldView(state),
  isSatOrRadarSelected: isSelectedFlightGroupSatType(state) || isSelectedFlightGroupRadarType(state)
})

const mapDispatchToProps = {
  setSelectedFieldTab
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(FileGeneratorButton)
